export const FETCHING_LANGUAGES = "FETCHING_LANGUAGES";
export const FETCHED_LANGUAGES = "FETCHED_LANGUAGES";
export const TOGGLE_TOAST = "TOGGLE_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";
export const UPDATE_EDITOR = "UPDATE_EDITOR";
export const SCROLL_VIEW_TARGET = "SCROLL_VIEW_TARGET";
export const CLEAR_SCROLL_VIEW_TARGET = "CLEAR_SCROLL_VIEW_TARGET";


export function fetchingLanguages() {
  return {
    type: FETCHING_LANGUAGES,
  };
}

export function fetchedLanguages(data) {
  return {
    type: FETCHED_LANGUAGES,
    payload: data,
  };
}

export function toggleToast(message: string) {
  return {
    type: TOGGLE_TOAST,
    payload: message
  };
}


export function hideToast() {
  return {
    type: HIDE_TOAST,
  };
}


export function updateEditor(key: string, value: string) {
  return {
    type: UPDATE_EDITOR,
    key,
    value
  };
}

export function scrollToView(target: string) {
  return {
    type: SCROLL_VIEW_TARGET,
    payload: target
  };
}



export function clearScrollTarget() {
  return {
    type: CLEAR_SCROLL_VIEW_TARGET,
  };
}

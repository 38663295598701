import { BredecApplicationDataImpl } from '../../models'
import { FETCHED_APP, FETCHING_APP, SAVEING_APP, APPLICATION_SET_PAGE_LOADING, APPLICATION_RESET_PAGE_LOADING } from '../actions/app-actions'


const APP_STATE = new BredecApplicationDataImpl()
  
export const application = (state = APP_STATE, action) => {
  switch (action.type) {
    case FETCHING_APP: {
      return {
        ...state,...action.payload,
        loading: true,
        checkInitiated: true
      }
    }
    case FETCHED_APP: {
      return {
        ...state,...action.payload,
        loading: false,
        checkInitiated: false
      }
    }
    case SAVEING_APP: {
      return {
        ...state,...action.payload,
        loading: false
      }
    }
    case APPLICATION_SET_PAGE_LOADING: {
      return {
        ...state,
        pageLoading: true,
        pageLoadingText: action.payload
      }
    }
    case APPLICATION_RESET_PAGE_LOADING: {
      return {
        ...state,
        pageLoading: false,
        pageLoadingText: ''
      }
    }
    default:
      return state
  }
}

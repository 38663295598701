import { CheckoutData } from 'bredec-typings'
import { BredecCheckoutDataImpl } from '../../models'
import { CHECKOUT_SAVE_USER_CARD, CHECKOUT_STORE_USER_BILLING_ADDRESS, CHECKOUT_STORE_USER_SHIPPING_ADDRESS, STORE_USER_CLIENT_SECRET } from '../actions/checkout'


const INITIAL_STATE = new BredecCheckoutDataImpl()
  
export const checkoutReducer = (state: CheckoutData = INITIAL_STATE, action): CheckoutData => {
  switch (action.type) {
        case CHECKOUT_SAVE_USER_CARD: {
            const isCardAlreadySaved = state.savedCards.some(card => card.id === action.payload.id);
            if(!isCardAlreadySaved){
                const savedCards = [...state.savedCards, action.payload]
                return {
                    ...state,
                    savedCards
                }
            }
            return state;
        }
        case CHECKOUT_STORE_USER_SHIPPING_ADDRESS: {
            return {
                ...state,
                shippingDetails: action.payload
            }
        }
        case CHECKOUT_STORE_USER_BILLING_ADDRESS: {
            return {
                ...state,
                billingDetails: action.payload
            }
        }
        case STORE_USER_CLIENT_SECRET: {
            return {
                ...state,
                client_setup_intent: action.payload
            }
        }
        default:
        return state
  }
}

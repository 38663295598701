import { LitElement, html, css, property } from 'lit-element'

export class BredecIcon extends LitElement {
  @property()
  size: string;
  @property()
  prefix!: string;
  @property()
  name!: string;
  @property()
  color!: string;
  @property()
  id!: string;
  static get styles() {
    return css`
      :host {
        display: inline-block;
      }
    `
  }
  constructor() {
    super()
    this.color = 'white'
    this.size = '1rem'
  }


  render() {
    return html`
      <link rel="stylesheet" href="../assets/css/all.min.css">
      <link rel="stylesheet" href="../assets/css/solid.min.css">
      <i class="${this.prefix ? this.prefix : 'fas'} fa-${this.name}" id="${this.id}" style="color : ${this.color}; font-size : ${this.size};"></i>
    `
  }
}

customElements.define('bredec-icon', BredecIcon)

import { LitElement, html, css, property } from "lit-element";

export class BredecSpinner extends LitElement {
    @property()
    type: 'loader'
  static get styles() {
    return css`
      :host {
        display: inline-block;
      }
      .loader,
      .loader:after {
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
      .loader {
        margin: 10px auto;
        font-size: 10px;
        position: relative;
        text-indent: -9999em;
        border-top: 5px solid rgba(255, 255, 255, 0.2);
        border-right: 5px solid rgba(255, 255, 255, 0.2);
        border-bottom: 5px solid rgba(255, 255, 255, 0.2);
        border-left: 5px solid #ffffff;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
      }
      @-webkit-keyframes load8 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      @keyframes load8 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      .loader-horizontal,
      .loader-horizontal:before,
      .loader-horizontal:after {
        border-radius: 50%;
        width: 1em;
        height: 1em;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: load7 1.8s infinite ease-in-out;
        animation: load7 1.8s infinite ease-in-out;
      }
      .loader-horizontal {
        color: #ffffff;
        font-size: 6px;
        margin: 4px auto;
        position: relative;
        text-indent: -9999em;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
        top: -12px;
      }
      .loader-horizontal:before,
      .loader-horizontal:after {
        content: "";
        position: absolute;
        top: 0;
      }
      .loader-horizontal:before {
        left: -3.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
      }
      .loader-horizontal:after {
        left: 3.5em;
      }
      @-webkit-keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
      @keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
    `;
  }

  render() {
    return html` <div class="${this.type}">Loading...</div> `;
  }
}

customElements.define("bredec-spinner", BredecSpinner);

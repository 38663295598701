import { html, LitElement, property } from "lit-element";
import { connect } from "pwa-helpers";
import { store } from "./redux/store";

import { getUserDetails } from "./redux/effects/app-effects";
import { registerTranslateConfig, use } from "lit-translate";

import "./components/bredec-icon";
import "./components/bredec-nav";
import "./components/bredec-consent";
import "./components/bredec-spinner";
import "./components/bredec-toast";

import styles from "./css/app-css";

// import "./pages/bredec-404";

import "./components/bredec-refresh-token-modal";

import { updateCartEffect } from "./redux/effects/shop-effects";
import { fetchAllLanguages } from "./redux/effects/meta";
import { Router, RouterLocation } from "@vaadin/router";
import { setAdminPageTitle } from './redux/actions/admin/meta';

class BredecApp extends connect(store)(LitElement) {
  @property()
  profile = {};
  @property()
  app!: any;
  @property()
  hasLoadedStrings: boolean;
  @property()
  route: any;
  @property()
  router: any;
  @property()
  showRefreshTokenModal: boolean;
  @property()
  isAuthenticated: boolean;

  constructor() {
    super();
    store.dispatch(getUserDetails());
    registerTranslateConfig({
      loader: (lang) =>
        fetch(`./assets/languages/${lang}.json`).then((res) => res.json()),
    });
    window.addEventListener("route-change", this.handleRouterChange);
    const localStorageCart = localStorage.getItem("cart");
    store.dispatch(updateCartEffect(JSON.parse(localStorageCart)));
    store.dispatch(fetchAllLanguages());
    this.hasLoadedStrings = false;
  }

  handleRouterChange(e) {
    Router.go(e.detail.path);
  }

  shouldUpdate(changedProperties) {
    return this.hasLoadedStrings && super.shouldUpdate(changedProperties);
  }

  async connectedCallback() {
    super.connectedCallback();
    await use("en-us");
    this.hasLoadedStrings = true;
  }

  stateChanged(state) {
    this.app = state.application;
    this.isAuthenticated = state.auth.isAuthenticated;
    this.requestUpdate();
  }

  protected updated(
    _changedProperties: Map<string | number | symbol, unknown>
  ): void {
    if (_changedProperties.has("route")) {
      store.dispatch(fetchAllLanguages());
    }
  }

  protected firstUpdated(
    _changedProperties: Map<string | number | symbol, unknown>
  ): void {
    const router = new Router(this.shadowRoot.querySelector("main"));
    router.setRoutes([
      {
        path: "/",
        redirect: "/auth",
      },
      {
        path: "/auth",
        component: "bredec-auth",
        action: async () => {
          await import(/* webpackChunkName: "auth" */ "./pages/bredec-auth");
        },
      },
      {
        path: "/signup",
        component: "bredec-signup",
        action: async () => {
          await import(/* webpackChunkName: "auth" */ "./pages/bredec-signup");
        },
      },
      {
        path: "/forgot-password",
        component: "bredec-forgot-password",
        action: async () => {
          await import(/* webpackChunkName: "auth" */ "./pages/bredec-forgot-password");
        },
      },
      {
        path: "admin",
        children: [
          {
            path: "",
            redirect: "dashboard",
          },
          {
            path: "dashboard",
            component: "bredec-admin-dashboard",
            action: async () => {
              store.dispatch(setAdminPageTitle("Dashboard"))
              await import("./pages/admin/dashboard");
            },
          },
          {
            path: "users",
            component: "bredec-admin-users",
            action: async () => {
              store.dispatch(setAdminPageTitle("Users"))
              await import("./pages/admin/users/users");
            },
          },
          {
            path: "groups",
            component: "bredec-admin-user-groups",
            action: async () => {
              store.dispatch(setAdminPageTitle("Groups"))
              await import("./pages/admin/groups/groups");
            },
          },
          {
            path: "aboutus",
            component: "bredec-admin-edit-about-us",
            action: async () => {
              store.dispatch(setAdminPageTitle("Edit About Us"))
              await import("./pages/admin/aboutus");
            },
          },
          {
            path: "portfolio",
            component: "bredec-admin-portfolio",
            action: async () => {
              store.dispatch(setAdminPageTitle("Portfolios"))
              await import("./pages/admin/portfolio/portfolio");
            },
          },
          {
            path: "portfolio/add",
            component: "bredec-admin-add-portfolio",
            action: async () => {
              store.dispatch(setAdminPageTitle("Add Portfolio"))
              await import("./pages/admin/portfolio/add-portfolio");
            },
          },
          {
            path: "portfolio/:id",
            component: "bredec-admin-edit-portfolio",
            action: async () => {
              store.dispatch(setAdminPageTitle("Edit Portfolio"))
              await import("./pages/admin/portfolio/edit-portfolio");
            },
          },
          {
            path: "news",
            component: "bredec-admin-news",
            action: async () => {
              store.dispatch(setAdminPageTitle("News"))
              await import("./pages/admin/news/news");
            },
          },
          {
            path: "news/add",
            component: "bredec-admin-add-news",
            action: async () => {
              store.dispatch(setAdminPageTitle("Add News"))
              await import("./pages/admin/news/add-news");
            },
          },
          {
            path: "news/:id/preview",
            component: "bredec-news-details",
            action: async () => {
              await import("./pages/bredec-news-details");
            },
          },
          {
            path: "news/:id",
            component: "bredec-admin-edit-news",
            action: async () => {
              store.dispatch(setAdminPageTitle("Edit News"))
              await import("./pages/admin/news/edit-news");
            },
          },
          {
            path: "portfolio/:id/preview",
            component: "bredec-portfolio-details",
            action: async () => {
              await import("./pages/bredec-portfolio-details");
            },
          }
        ],
      },
    ]);
  }
  onBeforeEnter(
    location: RouterLocation
  ) {
    console.log(location)
  }

  static styles = styles;

  render() {
    const isAdmin = window.location.href.includes("/admin/");
    return html`
      <bredec-nav ?hidden=${isAdmin}></bredec-nav>
      <main></main>
      <bredec-toast></bredec-toast>
      <bredec-refresh-token-modal></bredec-refresh-token-modal>
    `;
  }
}

customElements.define("bredec-app", BredecApp);

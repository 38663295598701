import { FullUserAccess } from "bredec-user-access-services-types";

export const gql = s => s[0];

export const routeChange = (route: string) => {
    const myevent = new CustomEvent('route-change', {
        detail: {
        path: route
        },
        bubbles: true,  
    })
    window.dispatchEvent(myevent)
}
// Format amount for diplay in the UI
export function formatAmount(amount, currency) {
  amount = zeroDecimalCurrency(amount, currency)
    ? amount
    : (amount / 100).toFixed(2);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(amount);
}

// Format amount for Stripe
export function formatAmountForStripe(amount, currency) {
  return zeroDecimalCurrency(amount, currency)
    ? amount
    : Math.round(amount * 100);
}

// Check if we have a zero decimal currency
// https://stripe.com/docs/currencies#zero-decimal
export function zeroDecimalCurrency(amount, currency) {
  const numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (const part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false;
    }
  }
  return zeroDecimalCurrency;
}
export const asyncLocalStorage = {
  setItem: function (key, value) {
      return Promise.resolve().then(function () {
          localStorage.setItem(key, value);
      });
  },
  getItem: function (key) {
      return Promise.resolve().then(function () {
          return localStorage.getItem(key);
      });
  }
};
export const validURL = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export const setMetatag = (attributeName, attributeValue, type = "name") => {
    const meta = document.createElement('meta');
    meta.setAttribute('http-equiv', 'X-UA-Compatible');
    meta.setAttribute(type, attributeName);
    meta.setAttribute('content', attributeValue);
    document.getElementsByTagName('head')[0].appendChild(meta);
}

export const updateMetatag = (attributeName, attributeValue, type = "name") => {
  document.querySelector('meta['+type+'="'+attributeName+'"]').setAttribute("content", attributeValue?.substring(0,160));
}

export const updatePageTitle = (title) => {
  if(title) {
    document.title = title + ' | Bredec';
  }
}

export function isObjectEmptyOrNull(obj) {
  if (obj === null) {
    return true;
  }

  if (typeof obj !== "object") {
    return false;
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] !== null && obj[key] !== "") {
        return false;
      }
    }
  }

  return true;
}

export const validateFields = (requiredFields: string[], value: any) => {
  let isValid = true;
  let emptyFields = [];
  if(requiredFields && requiredFields.length && value) {
    requiredFields.forEach(item => {
      if(!(item in value)) {
        isValid = false;
        emptyFields.push(item)
      } else if(typeof value[item] == "string" && (!value[item] || !value[item]?.length)) {
        isValid = false;
        emptyFields.push(item)
      } else if(Array.isArray(value[item]) && !value[item]?.length) {
        isValid = false;
        emptyFields.push(item)
      } else if(typeof value[item] == "object" && isObjectEmptyOrNull(value[item])) {
        isValid = false;
        emptyFields.push(item)
      }
    })
  } else if(!value) {
    isValid = false;
    emptyFields = [...requiredFields]
  }
  return { isValid, emptyFields };
}

export const checkPermissions = (data: FullUserAccess, collection: string, key: string) => {
  let result = false;
  let allPermissions = [];
  if(data && data.userAccess && data.userAccess.length > 0) {
    data.userAccess.forEach(userAccess => {
      if(userAccess.Permissions && userAccess.Permissions.length) {
        allPermissions = [
          ...allPermissions,
          ...userAccess.Permissions
        ];
      }
    })
  }
  if(data && data.groupsAccess && data.groupsAccess.length) {
    data.groupsAccess.forEach(groupAccess => {
      if(groupAccess.Permissions && groupAccess.Permissions.length) {
        allPermissions = [
          ...allPermissions,
          ...groupAccess.Permissions
        ]
      }
    })
  }
  const isCollectionPresent = allPermissions && allPermissions.length ? allPermissions.some(permission => permission.Collection === collection) : false;
  if(isCollectionPresent) {
      const permission = allPermissions.filter(permission => permission.Collection === collection);
      permission.forEach(singlePermission => {
        if (singlePermission.AccessLevel.indexOf(key) > -1) {
            result = true
        }
      })
  }
  return result;
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
// main.js - Main JavaScript file
export async function clearServiceWorkerCache() {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.getRegistration();
    if (registration && registration.active) {
      registration.active.postMessage('clearCache');
    }
  }
}

import { ApplicationData } from "bredec-typings";

export const FETCHING_APP = "FETCHING_APP";
export const FETCHED_APP = "FETCHED_APP";
export const SAVEING_APP = "SAVEING_APP";
export const APPLICATION_SET_PAGE_LOADING = "APPLICATION/SET_PAGE_LOADING";
export const APPLICATION_RESET_PAGE_LOADING = "APPLICATION/RESET_PAGE_LOADING";

export function fetchingApp() {
  return {
    type: FETCHING_APP,
  };
}
export function fetchedApp(appDetail: ApplicationData) {
  return {
    type: FETCHED_APP,
    payload: appDetail,
  };
}

export function saveApp(appDetail: ApplicationData) {
  return {
    type: SAVEING_APP,
    payload: appDetail,
  };
}

export const setPageLoading = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: APPLICATION_SET_PAGE_LOADING,
      payload: data,
    });
  };
};
export const resetPageLoading = () => {
  return (dispatch) => {
    dispatch({
      type: APPLICATION_RESET_PAGE_LOADING,
    });
  };
};


import {
  LanguageResponse,
} from "bredec-language-service-types";
import { GQLQuery } from "bredec-typings";
import { gql } from "../../../utils/utils";

type LanguagesRequestDTO = {};

const Query = gql`
  query {
    languages {
      Key
      Code
      Name
    }
  }
`;

export const getLanguagesRequest = (): GQLQuery<LanguagesRequestDTO> => {
  return {
    query: Query,
    variables: {},
  };
};

export const parseLanguagesResponse = (json): LanguageResponse => {
  const { data, errors } = json;
  if (!errors) {
    const response: LanguageResponse = data?.languages;
    return response;
  }
};

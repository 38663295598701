import { CLEAR_SELECTED_ABOUTUS, FETCHED_ABOUTUS, FETCHED_SINGLE_ABOUTUS, FETCHING_ABOUTUS, UPDATE_ABOUTUS, UPDATE_ABOUTUS_RESET, UPDATE_ABOUTUS_SUCCESS, UPDATE_ABOUTUS_VALUE } from '../../actions/admin/aboutus'


const INITIAL_STATE = {}
  
export const aboutUsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
        case FETCHING_ABOUTUS: {
            return {
                ...state,
                loading: true
            }
        }
        case FETCHED_ABOUTUS: {
            const { payload } = action;
            return {
                ...state,
                loading: false,
                aboutus: payload
            }
        }
        case FETCHED_SINGLE_ABOUTUS: {
            const { payload } = action;
            return {
                ...state,
                loading: false,
                selectedAboutUs: payload ? payload[0]: {}
            }
        }
        case UPDATE_ABOUTUS: {
            return {
                ...state,
                updateAboutUsState: true
            }
        }
        case UPDATE_ABOUTUS_SUCCESS: {
            const { payload } = action;
            return {
                ...state,
                updateAboutUsState: false,
                selectedAboutUs: payload
            }
        }
        case UPDATE_ABOUTUS_RESET: {
            return {
                ...state,
                updateAboutUsState: false
            }
        }
        case UPDATE_ABOUTUS_VALUE: {
            const {key, value} = action.payload
            return {
                ...state,
                selectedAboutUs: {
                    // @ts-ignore
                    ...state?.selectedAboutUs,
                    [key]: value

                }
            }
        }
        case CLEAR_SELECTED_ABOUTUS: {
            return {
                ...state,
                selectedAboutUs: {}
            }
        }
        default:
        return state
  }
}

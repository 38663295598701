import { FilterUserAccess, FullUserAccessRequest, FullUserAccessResponse } from "bredec-user-access-services-types";
import { GQLQuery } from "bredec-typings";
import { gql } from "../../../../utils/utils";

type UserAccessRequestDTO = {
  filter: FilterUserAccess | {};
};

const Query = gql`
  query(
    $filter: FilterUserAccess
  )  {
    userAccess(
      filter: $filter
    ) {
      Key
      UserKey
      Permissions{
          Collection
          AccessLevel
      }
    }
    groupsAccess(
      filter: $filter
    ){
      Key
      Alias
      Permissions{
          Collection
          AccessLevel
      }
    }
  }
`;

export const getUserAccessRequest = (
  userAccessRequestData: FullUserAccessRequest
): GQLQuery<UserAccessRequestDTO> => {
  const { filter } = userAccessRequestData;
  return {
    query: Query,
    variables: {
      filter
    }
  };
};

export const parseUserAccessResponse = (json): FullUserAccessResponse => {
  const { data, errors } = json;
  if (!errors) {
    const response: FullUserAccessResponse = data;
    return response;
  }
};

import { LanguageRequest, LanguageResponse } from "bredec-language-service-types";
import { getLanguagesRequest, parseLanguagesResponse } from "./gql";
import { environment} from "../../const";
import abortSignalManager from "../../utils/abort-signal-manager";

export const getLanguages = async (
    languageRequest: LanguageRequest
): Promise<LanguageResponse> => {
    const { abortSignal } = languageRequest;
    const timer = setTimeout(() => abortSignalManager.abort("bredec", "languages"), 10000);
    const requestBody = getLanguagesRequest();
    try {
        const response = await fetch(environment.apiUrl, {
            method: "POST",
            credentials: "same-origin",
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
            signal: abortSignal
        });
        const json = await response.json();
        clearTimeout(timer);
        return parseLanguagesResponse(json);
    } catch (error) {
        if (error.name === 'AbortError') {
          throw new Error('Request timed out');
        } else {
          throw error;
        }
    }
}

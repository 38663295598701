export const FETCHING_USER_GROUPS = "ADMIN/USER_GROUPS/FETCHING";
export const fetchUserGroups = () => {
  return {
    type: FETCHING_USER_GROUPS,
  };
};

export const FETCHED_USER_GROUPS = "ADMIN/USER_GROUPS/FETCHED";
export const fetchedUserGroups = (data) => {
  return {
    type: FETCHED_USER_GROUPS,
    payload: data,
  };
};

export const FETCHED_SINGLE_USER_GROUPS = "ADMIN/SINGLE_USER_GROUPS/FETCHED";
export const fetchedSingleUserGroups = (data) => {
  return {
    type: FETCHED_SINGLE_USER_GROUPS,
    payload: data,
  };
};


export const CLEAR_SELECTED_USER_GROUP = "ADMIN/USER_GROUP/CLEAR";
export const clearUserGroup = () => {
  return {
    type: CLEAR_SELECTED_USER_GROUP,
  };
};

export const CREATE_USER_GROUP = "ADMIN/USER_GROUP/CREATE";
export const createUserGroup = () => {
  return {
    type: CREATE_USER_GROUP,
  };
};

export const CREATE_USER_GROUP_SUCCESS = "ADMIN/USER_GROUP/CREATE_SUCCESS";
export const createUserGroupSuccess = (data) => {
  return {
    type: CREATE_USER_GROUP_SUCCESS,
    payload: data,
  };
};

export const CREATE_USER_GROUP_RESET = "ADMIN/USER_GROUP/CREATE_RESET";
export const createUserGroupReset = () => {
  return {
    type: CREATE_USER_GROUP_RESET,
  };
};

export const UPDATE_USER_GROUP = "ADMIN/USER_GROUP/UPDATE";
export const updateUserGroupAction = () => {
  return {
    type: UPDATE_USER_GROUP,
  };
};

export const UPDATE_USER_GROUP_SUCCESS = "ADMIN/USER_GROUP/UPDATE_SUCCESS";
export const updateUserGroupSuccess = (data) => {
  return {
    type: UPDATE_USER_GROUP_SUCCESS,
    payload: data,
  };
};

export const UPDATE_USER_GROUP_RESET = "ADMIN/USER_GROUP/UPDATE_RESET";
export const updateUserGroupReset = () => {
  return {
    type: UPDATE_USER_GROUP_RESET,
  };
};

export const DELETE_USER_GROUP = "ADMIN/USER_GROUP/DELETE";
export const deleteUserGroup = () => {
  return {
    type: DELETE_USER_GROUP,
  };
};

export const DELETE_USER_GROUP_SUCCESS = "ADMIN/USER_GROUP/DELETE_SUCCESS";
export const deleteUserGroupSuccess = (data) => {
  return {
    type: DELETE_USER_GROUP_SUCCESS,
    payload: data,
  };
};
export const DELETE_USER_GROUP_RESET = "ADMIN/USER_GROUP/DELETE_RESET";
export const deleteUserGroupReset = () => {
  return {
    type: DELETE_USER_GROUP_RESET,
  };
};

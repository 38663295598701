import { CLEAR_SELECTED_PORTFOLIO, CREATE_PORTFOLIO, CREATE_PORTFOLIO_RESET, CREATE_PORTFOLIO_SUCCESS, DELETE_PORTFOLIO, DELETE_PORTFOLIO_SUCCESS, FETCHED_PORTFOLIOS, FETCHED_SINGLE_PORTFOLIOS, FETCHING_PORTFOLIO, UPDATE_PORTFOLIO, UPDATE_PORTFOLIO_RESET, UPDATE_PORTFOLIO_SUCCESS, UPDATE_PORTFOLIO_VALUE } from '../../actions/admin/portfolio'


const INITIAL_STATE = {}
  
export const portfolioReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
        case FETCHING_PORTFOLIO: {
            return {
                ...state,
                loading: true
            }
        }
        case FETCHED_PORTFOLIOS: {
            const { payload } = action;
            return {
                ...state,
                loading: false,
                portfolios: payload
            }
        }
        case FETCHED_SINGLE_PORTFOLIOS: {
            const { payload } = action;
            return {
                ...state,
                loading: false,
                selectedPortfolio: payload ? payload[0]: {}
            }
        }
        case CREATE_PORTFOLIO: {
            return {
                ...state,
                createPortfolioState: false
            }
        }
        case CREATE_PORTFOLIO_SUCCESS: {
            return {
                ...state,
                createPortfolioState: true
            }
        }
        case CREATE_PORTFOLIO_RESET: {
            return {
                ...state,
                createPortfolioState: false
            }
        }
        case UPDATE_PORTFOLIO: {
            return {
                ...state,
                updatePortfolioState: true
            }
        }
        case UPDATE_PORTFOLIO_SUCCESS: {
            const { payload } = action;
            return {
                ...state,
                updatePortfolioState: false,
                selectedPortfolio: payload
            }
        }
        case UPDATE_PORTFOLIO_RESET: {
            return {
                ...state,
                updatePortfolioState: false
            }
        }
        case UPDATE_PORTFOLIO_VALUE: {
            const {key, value} = action.payload
            return {
                ...state,
                selectedPortfolio: {
                    // @ts-ignore
                    ...state?.selectedPortfolio,
                    [key]: value

                }
            }
        }
        case CLEAR_SELECTED_PORTFOLIO: {
            return {
                ...state,
                selectedPortfolio: {}
            }
        }
        case DELETE_PORTFOLIO: {
            return {
                ...state,
                deletePortfolioState: true
            }
        }
        case DELETE_PORTFOLIO_SUCCESS: {
            return {
                ...state,
                deletePortfolioState: false
            }
        }
        default:
        return state
  }
}

import Dexie from 'dexie';
const DB_NAME = 'BredecDB';
const DB_STORE = 'BredecStore';

export function openIndexedDB() {
  // @ts-ignore
  const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

  if (!indexedDB) {
    window.alert("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.");
    return;
  }

  const openDB = new Dexie(DB_NAME);
  openDB.version(1).stores({
    [DB_STORE]: '++id,data',
  });
  return openDB;
}

export function getStoreIndexedDB(openDB) {
  // let db = {};
  // console.log(openDB);
  // db['result'] = openDB.result;
  // db['tx'] = db['result'].transaction(DB_STORE, "readwrite");
  // db['store'] = db['tx'].objectStore(DB_STORE);
  return openDB[DB_STORE];
}

import { AuthData } from 'bredec-typings'
import { checkPermissions } from '../../utils/utils'
import { BredecAuthDataImpl } from '../../models'
import { AUTH_LOGOUT_SUCCESS, AUTH_SET_LOADING_STATE, AUTH_SUCCESS, AUTH_ADMIN_DETAILS_SUCCESS, AUTH_FAIL, AUTH_TOKEN_REFRESH, AUTH_TOKEN_REFRESH_FAIL, AUTH_TOKEN_REFRESH_SUCCESS, SET_AUTH_PERMISSIONS } from '../actions/auth'


const INITIAL_STATE = new BredecAuthDataImpl()
  
export const authReducer = (state: AuthData = INITIAL_STATE, action): AuthData => {
  switch (action.type) {
        case AUTH_SET_LOADING_STATE: {
            return {
                ...state,
                loading: action.payload,
            }
        }
        case AUTH_SUCCESS: {
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: action.payload
            }
        }
        case SET_AUTH_PERMISSIONS: {
            const data = action.payload;
            return {
                ...state,
                permissions: {
                    canCreateUser: checkPermissions(data, 'User', 'c'),
                    canReadUser: checkPermissions(data, 'User', 'r'),
                    canUpdateUser: checkPermissions(data, 'User', 'u'),
                    canDeleteUser: checkPermissions(data, 'User', 'd'),
                    isOwnerOfUser: checkPermissions(data, 'User', 'o'),
                    canCreateAboutUs: checkPermissions(data, 'ContentAboutUs', 'c'),
                    canReadAboutUs: checkPermissions(data, 'ContentAboutUs', 'r'),
                    canUpdateAboutUs: checkPermissions(data, 'ContentAboutUs', 'u'),
                    canDeleteAboutUs: checkPermissions(data, 'ContentAboutUs', 'd'),
                    isOwnerOfAboutUs: checkPermissions(data, 'ContentAboutUs', 'o'),
                    canCreateNews: checkPermissions(data, 'ContentNews', 'c'),
                    canReadNews: checkPermissions(data, 'ContentNews', 'r'),
                    canUpdateNews: checkPermissions(data, 'ContentNews', 'u'),
                    canDeleteNews: checkPermissions(data, 'ContentNews', 'd'),
                    isOwnerOfNews: checkPermissions(data, 'ContentNews', 'o'),
                    canCreatePortfolio: checkPermissions(data, 'ContentPortfolio', 'c'),
                    canReadPortfolio: checkPermissions(data, 'ContentPortfolio', 'r'),
                    canUpdatePortfolio: checkPermissions(data, 'ContentPortfolio', 'u'),
                    canDeletePortfolio: checkPermissions(data, 'ContentPortfolio', 'd'),
                    isOwnerOfPortfolio: checkPermissions(data, 'ContentPortfolio', 'o'),
                    canCreateUserAccess: checkPermissions(data, 'UserAccess', 'c'),
                    canReadUserAccess: checkPermissions(data, 'UserAccess', 'r'),
                    canUpdateUserAccess: checkPermissions(data, 'UserAccess', 'u'),
                    canDeleteUserAccess: checkPermissions(data, 'UserAccess', 'd'),
                    isOwnerOfUserAccess: checkPermissions(data, 'UserAccess', 'o'),
                }
            }
        }
        case AUTH_FAIL: {
            return {
                ...state,
                isAuthenticated: false,
                loading: false
            }
        }
        case AUTH_LOGOUT_SUCCESS: {
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                user: null,
                isAdmin: false
            }
        }
        case AUTH_ADMIN_DETAILS_SUCCESS: {
            return {
                ...state,
                isAdmin: true
            }
        }
        case AUTH_TOKEN_REFRESH: {
            return {
                ...state,
                refreshTokenState: true
            }
        }
        case AUTH_TOKEN_REFRESH_SUCCESS: {
            return {
                ...state,
                refreshTokenState: false
            }
        }
        case AUTH_TOKEN_REFRESH_FAIL: {
            return {
                ...state,
                refreshTokenState: false
            }
        }
        default:
        return state
  }
}

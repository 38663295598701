import { CLEAR_USER_ACCESS, CREATE_USER_ACCESS, CREATE_USER_ACCESS_RESET, CREATE_USER_ACCESS_SUCCESS, FETCHED_SINGLE_USER_ACCESS, FETCHED_SINGLE_USER_GROUP_ACCESS, FETCHED_USER_ACCESS, FETCHING_USER_ACCESS, UPDATE_SELECTED_SINGLE_USER_ACCESS, UPDATE_SELECTED_USER_TYPE, UPDATE_USER_ACCESS, UPDATE_USER_ACCESS_RESET, UPDATE_USER_ACCESS_SUCCESS } from "../../actions/admin/access"

const INITIAL_STATE = {}

export const userAccessReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
          case FETCHING_USER_ACCESS: {
              return {
                  ...state,
                  loading: true
              }
          }
          case FETCHED_USER_ACCESS: {
            const { payload } = action;
              return {
                  ...state,
                  loading: false,
                  access: payload,
              }
          }
          case FETCHED_SINGLE_USER_ACCESS: {
            const { payload } = action;
            return {
                ...state,
                loading: false,
                selectedAccess: payload ? payload[0]: {},
                selectedUserAccess: payload && payload[0].userAccess && payload[0].userAccess.length ? payload[0].userAccess[0]  : {}
            }
          }
          case FETCHED_SINGLE_USER_GROUP_ACCESS: {
            const { payload } = action;
            return {
                ...state,
                loading: false,
                selectedUserAccess: payload ? payload[0]  : {}
            }
          }
          case CLEAR_USER_ACCESS: {
            return {
                ...state,
                loading: false,
                selectedAccess: {},
                selectedUserAccess: []
            }
          }
          case CREATE_USER_ACCESS: {
              return {
                  ...state,
                  createUserAccessState: true
              }
          }
          case CREATE_USER_ACCESS_SUCCESS: {
              return {
                  ...state,
                  createUserAccessState: false
              }
          }
          case CREATE_USER_ACCESS_RESET: {
              return {
                  ...state,
                  createUserAccessState: false
              }
          }
          case UPDATE_USER_ACCESS: {
              return {
                  ...state,
                  updateUserAccessState: true
              }
          }
          case UPDATE_USER_ACCESS_SUCCESS: {
              const { payload } = action;
              return {
                  ...state,
                  updateUserAccessState: false,
                  selectedAccess: payload,
              }
          }
          case UPDATE_USER_ACCESS_RESET: {
              return {
                  ...state,
                  updateUserAccessState: false
              }
          }
          case UPDATE_SELECTED_SINGLE_USER_ACCESS: {
              return {
                  ...state,
                  selectedUserAccess: action.payload
              }
          }
          case UPDATE_SELECTED_USER_TYPE: {
            return {
                ...state,
                userType: action.payload
            }
          }
          default:
          return state
    }
  }
  
import { AboutProfileData } from "bredec-typings"

export const FETCHING_PROFILE = 'FETCHING_PROFILE'
export const FETCHED_PROFILE = 'FETCHED_PROFILE'
export const SAVING_PROFILE = 'SAVING_PROFILE'

export function fetchingProfile() {
  return {
    type: FETCHING_PROFILE
  }
}
export function fetchedProfile(profile: AboutProfileData) {
    return {
      type: FETCHED_PROFILE,
      payload: profile
    }
}

export function saveProfile(profile: AboutProfileData){
    return {
        type: SAVING_PROFILE,
        payload: profile
    }
}
export const FETCHING_NEWS = "ADMIN/NEWS/FETCHING";
export const fetchNews = () => {
  return {
    type: FETCHING_NEWS,
  };
};

export const FETCHED_NEWS = "ADMIN/NEWS/FETCHED";
export const fetchedNews = (data) => {
  return {
    type: FETCHED_NEWS,
    payload: data,
  };
};
export const FETCHED_SINGLE_NEWS = "ADMIN/SINGLE_NEWS/FETCHED";
export const fetchedSingleNews = (data) => {
  return {
    type: FETCHED_SINGLE_NEWS,
    payload: data,
  };
};

export const CREATE_NEWS = "ADMIN/NEWS/CREATE";
export const createAdminNews = () => {
  return {
    type: CREATE_NEWS,
  };
};

export const CREATE_NEWS_SUCCESS = "ADMIN/NEWS/CREATE_SUCCESS";
export const createAdminNewsSuccess = (data) => {
  return {
    type: CREATE_NEWS_SUCCESS,
    payload: data,
  };
};

export const CREATE_NEWS_RESET = "ADMIN/NEWS/CREATE_RESET";
export const createAdminNewsReset = () => {
  return {
    type: CREATE_NEWS_RESET,
  };
};

export const UPDATE_NEWS = "ADMIN/NEWS/UPDATE";
export const updateAdminNews = () => {
  return {
    type: UPDATE_NEWS,
  };
};

export const UPDATE_NEWS_SUCCESS = "ADMIN/NEWS/UPDATE_SUCCESS";
export const updateAdminNewsSuccess = (data) => {
  return {
    type: UPDATE_NEWS_SUCCESS,
    payload: data,
  };
};

export const UPDATE_NEWS_RESET = "ADMIN/NEWS/UPDATE_RESET";
export const updateAdminNewsReset = () => {
  return {
    type: UPDATE_NEWS_RESET,
  };
};

export const UPDATE_NEWS_VALUE = "ADMIN/NEWS/UPDATE_VALUE";
export const updateNewsValue = (key: string, value: string) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NEWS_VALUE,
      payload: {
          key,
          value
      }
    });
  };
};

export const CLEAR_SELECTED_NEWS = "ADMIN/NEWS/CLEAR";
export const clearSelectedNews = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SELECTED_NEWS
    });
  };
};

export const DELETE_NEWS = "ADMIN/NEWS/DELETE";
export const deleteAdminNews = () => {
  return {
    type: DELETE_NEWS,
  };
};

export const DELETE_NEWS_SUCCESS = "ADMIN/NEWS/DELETE_SUCCESS";
export const deleteAdminNewsSuccess = (data) => {
  return {
    type: DELETE_NEWS_SUCCESS,
    payload: data,
  };
};
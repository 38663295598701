
import { FETCHING_USERS, FETCHED_USERS, FETCHED_SINGLE_USER, UPDATE_USER_VALUE, UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_RESET, DELETE_USER, DELETE_USER_SUCCESS, CLEAR_SELECTED_USER } from "../../actions/admin/users"
const INITIAL_STATE = {}

export const usersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
          case FETCHING_USERS: {
              return {
                  ...state,
                  loading: true
              }
          }
          case FETCHED_USERS: {
            const { payload } = action;
              return {
                  ...state,
                  loading: false,
                  users: payload,
                  deleteUserState: false
              }
          }
          case FETCHED_SINGLE_USER: {
            const { payload } = action;
            return {
                ...state,
                loading: false,
                selectedUser: payload ? payload[0]: {}
            }
          }
          case CLEAR_SELECTED_USER: {
            return {
                ...state,
                loading: false,
                selectedUser: {},
                updateUserState: false,
                deleteUserState: false
            }
          }
          case UPDATE_USER: {
              return {
                  ...state,
                  updateUserState: true
              }
          }
          case UPDATE_USER_SUCCESS: {
              const { payload } = action;
              return {
                  ...state,
                  updateUserState: false,
                  selectedUser: payload
              }
          }
          case UPDATE_USER_RESET: {
              return {
                  ...state,
                  updateUserState: false
              }
          }
          case UPDATE_USER_VALUE: {
              const {key, value} = action.payload
              return {
                  ...state,
                  selectedUser: {
                      // @ts-ignore
                      ...state?.selectedUser,
                      [key]: value
  
                  }
              }
          }
          case DELETE_USER: {
              return {
                  ...state,
                  deleteUserState: true
              }
          }
          case DELETE_USER_SUCCESS: {
              return {
                  ...state,
                  deleteUserState: false
              }
          }
          default:
          return state
    }
  }
  
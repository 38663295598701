import { css } from 'lit-element'

export default css`
  :host {
      width: 100%;
      line-height: normal;
    }
 

      @media only screen and (max-width: 768px) {
        .content{
          padding: 0 30px;
        }
        
      }
      
      
`

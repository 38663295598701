import { combineReducers } from 'redux'
import { productReducer as productsData } from './products';
import { metaReducer as metaData } from './meta';
import { portfolioReducer as portfoliosData } from './portfolio';
import { newsReducer as newsData } from './news';
import { aboutUsReducer as aboutUsData } from './aboutus';
import { usersReducer as usersData} from './users';
import {userGroupsReducer as userGroupsData } from './groups'
import {userAccessReducer as userAccessData } from './access';

export const adminReducer = combineReducers({
    productsData,
    portfoliosData,
    newsData,
    aboutUsData,
    metaData,
    usersData,
    userGroupsData,
    userAccessData
})
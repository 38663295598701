import { AddressDetails } from "bredec-typings";

export const CHECKOUT_SAVE_USER_CARD = 'CHECKOUT/SAVE_USER_CARD';
export const CHECKOUT_STORE_USER_SHIPPING_ADDRESS = 'CHECKOUT/CHECKOUT_STORE_USER_SHIPPING_ADDRESS';
export const CHECKOUT_STORE_USER_BILLING_ADDRESS = 'CHECKOUT/CHECKOUT_STORE_USER_BILLING_ADDRESS';
export const STORE_USER_CLIENT_SECRET = 'CHECKOUT/STORE_USER_CLIENT_SECRET';


export function saveUserCards(card: any) {
    return {
      type: CHECKOUT_SAVE_USER_CARD,
      payload: card
    }
}

export const storeUserShippingDetails = (shippingDetails: AddressDetails) => { 
    return dispatch => {
        dispatch({
            type: CHECKOUT_STORE_USER_SHIPPING_ADDRESS,
            payload: shippingDetails
        })
    }
}

export function storeUserClientSecret(key: any) {
    return {
      type: STORE_USER_CLIENT_SECRET,
      payload: key
    }
}

export const storeUserBillingDetails = (billingDetails: AddressDetails) => { 
    return dispatch => {
        dispatch({
            type: CHECKOUT_STORE_USER_BILLING_ADDRESS,
            payload: billingDetails
        })
    }
}
import { FETCHING_USER_GROUPS, FETCHED_USER_GROUPS, FETCHED_SINGLE_USER_GROUPS, UPDATE_USER_GROUP, UPDATE_USER_GROUP_SUCCESS, UPDATE_USER_GROUP_RESET, CREATE_USER_GROUP, CREATE_USER_GROUP_SUCCESS, CREATE_USER_GROUP_RESET, DELETE_USER_GROUP, DELETE_USER_GROUP_SUCCESS, DELETE_USER_GROUP_RESET, CLEAR_SELECTED_USER_GROUP } from "../../actions/admin/groups"

const INITIAL_STATE = {}

export const userGroupsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
          case FETCHING_USER_GROUPS: {
              return {
                  ...state,
                  loading: true
              }
          }
          case FETCHED_USER_GROUPS: {
            const { payload } = action;
              return {
                  ...state,
                  loading: false,
                  groups: payload,
                  deleteGroupState: false
              }
          }
          case FETCHED_SINGLE_USER_GROUPS: {
            const { payload } = action;
            return {
                ...state,
                loading: false,
                selectedGroup: payload ? payload[0]: {}
            }
          }
          case CLEAR_SELECTED_USER_GROUP: {
            return {
                ...state,
                loading: false,
                selectedGroup: {},
                createUserGroupState: false,
                updateUserGroupState: false,
                deleteUserGroupState: false
            }
          }
          case CREATE_USER_GROUP: {
              return {
                  ...state,
                  createUserGroupState: true
              }
          }
          case CREATE_USER_GROUP_SUCCESS: {
              return {
                  ...state,
                  createUserGroupState: false
              }
          }
          case CREATE_USER_GROUP_RESET: {
              return {
                  ...state,
                  createUserGroupState: false
              }
          }
          case UPDATE_USER_GROUP: {
              return {
                  ...state,
                  updateUserGroupState: true
              }
          }
          case UPDATE_USER_GROUP_SUCCESS: {
              const { payload } = action;
              return {
                  ...state,
                  updateUserGroupState: false,
                  selectedGroup: payload
              }
          }
          case UPDATE_USER_GROUP_RESET: {
              return {
                  ...state,
                  updateUserGroupState: false
              }
          }
          case DELETE_USER_GROUP: {
              return {
                  ...state,
                  deleteUserGroupState: false
              }
          }
          case DELETE_USER_GROUP_SUCCESS: {
              return {
                  ...state,
                  deleteUserGroupState: true
              }
          }
          case DELETE_USER_GROUP_RESET: {
            return {
                ...state,
                deleteUserGroupState: false
            }
          }
          default:
          return state
    }
  }
  
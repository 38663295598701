import { CLEAR_SELECTED_NEWS, CREATE_NEWS, CREATE_NEWS_RESET, CREATE_NEWS_SUCCESS, DELETE_NEWS, DELETE_NEWS_SUCCESS, FETCHED_NEWS, FETCHED_SINGLE_NEWS, FETCHING_NEWS, UPDATE_NEWS, UPDATE_NEWS_RESET, UPDATE_NEWS_SUCCESS, UPDATE_NEWS_VALUE } from '../../actions/admin/news'


const INITIAL_STATE = {}
  
export const newsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
        case FETCHING_NEWS: {
            return {
                ...state,
                loading: true
            }
        }
        case FETCHED_NEWS: {
            const { payload } = action;
            return {
                ...state,
                loading: false,
                news: payload,
                deleteNewsState: false
            }
        }
        case FETCHED_SINGLE_NEWS: {
            const { payload } = action;
            return {
                ...state,
                loading: false,
                selectedNews: payload ? payload[0]: {}
            }
        }
        case CREATE_NEWS: {
            return {
                ...state,
                createNewsState: false
            }
        }
        case CREATE_NEWS_SUCCESS: {
            return {
                ...state,
                createNewsState: true
            }
        }
        case CREATE_NEWS_RESET: {
            return {
                ...state,
                createNewsState: false
            }
        }
        case UPDATE_NEWS: {
            return {
                ...state,
                updateNewsState: true
            }
        }
        case UPDATE_NEWS_SUCCESS: {
            const { payload } = action;
            return {
                ...state,
                updateNewsState: false,
                selectedNews: payload
            }
        }
        case UPDATE_NEWS_RESET: {
            return {
                ...state,
                updateNewsState: false
            }
        }
        case UPDATE_NEWS_VALUE: {
            const {key, value} = action.payload
            return {
                ...state,
                selectedNews: {
                    // @ts-ignore
                    ...state?.selectedNews,
                    [key]: value

                }
            }
        }
        case CLEAR_SELECTED_NEWS: {
            return {
                ...state,
                selectedNews: {}
            }
        }
        case DELETE_NEWS: {
            return {
                ...state,
                deleteNewsState: false
            }
        }
        case DELETE_NEWS_SUCCESS: {
            return {
                ...state,
                deleteNewsState: true
            }
        }
        default:
        return state
  }
}

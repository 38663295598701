import { BredecOfferingsDataImpl } from '../../models'
import { FETCHED_PRODUCTS, FETCHING_PRODUCTS, FETCHING_NEWS, FETCHING_SERVICES, FETCHING_EVENTS,
FETCHED_NEWS, FETCHED_SERVICES, FETCHED_EVENTS, UPDATE_OFFERING, FETCHING_PORTFOLIO, FETCHED_SINGLE_PORTFOLIOS, FETCHED_SINGLE_NEWS, FETCHING_ABOUTUS, FETCHED_SINGLE_ABOUTUS, FETCHED_MORE_NEWS, FETCHING_MORE_NEWS, FETCHING_MORE_SERVICES, FETCHED_MORE_SERVICES } from '../actions/offerings-actions'


const INITIAL_STATE = new BredecOfferingsDataImpl()
  
export const offerings = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_OFFERING: {
      return {
        ...state,
        selectedOffering: action.payload,
        products: [], 
        news: [], 
        services: [],
        events: [],
      }
    }
    case FETCHING_PRODUCTS: {
      return {
        ...state,
        products: [], 
        loading: true
      }
    }
    case FETCHED_PRODUCTS: {
      return {
        ...state,
        products: action.payload, 
        loading: false
      }
    }
    case FETCHING_NEWS: {
      return {
        ...state,
        news: [], 
        loading: true
      }
    }
    case FETCHING_MORE_NEWS: {
      return {
        ...state,
        loading: true
      }
    }
    case FETCHED_NEWS: {
      return {
        ...state,
        news: action.payload?.length ? action.payload?.filter(item => item?.Publication === true) : [], 
        loading: false,
        loadMoreNews: action.payload?.length && action.payload?.length < 10 ? false : true
      }
    }
    case FETCHED_MORE_NEWS: {
      const news = action.payload?.length ? action.payload?.filter(item => item?.Publication === true) : []
      return {
        ...state,
        news: [
          ...state.news,
          ...news
        ], 
        loading: false,
        loadMoreNews: action.payload?.length ? true : false
      }
    }
    case FETCHING_SERVICES: {
      return {
        ...state,
        services: [], 
        loading: true
      }
    }
    case FETCHING_MORE_SERVICES: {
      return {
        ...state,
        loading: true
      }
    }
    case FETCHED_SERVICES: {
      return {
        ...state,
        services: action.payload?.length ? action.payload?.filter(item => item?.Publication === true) : [], 
        loading: false,
        loadMorePortfolios: action.payload?.length && action.payload?.length < 10 ? false : true
      }
    }
    case FETCHED_MORE_SERVICES: {
      const services = action.payload?.length ? action.payload?.filter(item => item?.Publication === true) : []
      return {
        ...state,
        services: [
          ...state.services,
          ...services
        ], 
        loading: false,
        loadMorePortfolios: action.payload?.length ? true : false
      }
    }
    case FETCHING_EVENTS: {
      return {
        ...state,
        events: [], 
        loading: true
      }
    }
    case FETCHED_EVENTS: {
      return {
        ...state,
        events: action.payload, 
        loading: false
      }
    }
    case FETCHING_PORTFOLIO: {
      return {
        ...state,
        loading: true
      }
    }
    case FETCHED_SINGLE_PORTFOLIOS: {
      return {
        ...state,
        loading: false,
        selectedPortfolio: action.payload
      }
    }
    case FETCHED_SINGLE_NEWS: {
      return {
        ...state,
        loading: false,
        selectedNews: action.payload
      }
    }
    case FETCHING_ABOUTUS: {
      return {
        ...state,
        loading: true
      }
    }
    case FETCHED_SINGLE_ABOUTUS: {
      return {
        ...state,
        loading: false,
        aboutUs: action.payload[0]
      }
    }
    default:
      return state
  }
}

export const FETCHING_PRODUCTS = 'ADMIN/PRODUCTS/FETCHING'
export const fetchProducts = () => { 
    return {
        type: FETCHING_PRODUCTS,
    }
}

export const FETCHED_PRODUCTS = 'ADMIN/PRODUCTS/FETCHED'
export const fetchedProducts = (data) => { 
    return {
        type: FETCHED_PRODUCTS,
        payload: data

    }
}


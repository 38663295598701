import { LitElement, html, property, css } from "lit-element";
import { connect } from "pwa-helpers";
import { refreshToken } from "../redux/effects/auth";
import { store } from '../redux/store'
import moment from "moment";

export class RefreshToken extends connect(store)(LitElement) {
    @property()
    open: boolean
    @property()
    heading: string
    @property()
    submitBtnText: string;
    @property()
    refreshTokenState: boolean;
    @property()
    tokenModalInterval: any;
    @property()
    isAuthenticated: boolean;
    @property()
    timeoutStarted = false;



    static get styles() {
        return [
            css`
            .modal {
                opacity: 0;
                transition: visibility 0s, opacity 0.25s ease-in;
                width: 650px;
                background: #000;
                border: 1px solid #fff;
                padding: 20px;
                z-index: 99999;
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 1;

            }
            .modal:not(.open) {
                visibility: hidden;
            }
            .modal.open {
                visibility: visible;
            }
            .overlay {
                background: rgba(0, 0, 0, 0.8);
                height: 100%;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                z-index: 9999;
            }
            .modal-data {
                position: relative;
                z-index: 99999;
            }
            .modal-header {
                display: flex;
                align-content: center;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #fff;
                padding-bottom: 15px;
                margin-bottom: 20px;
            }
            .modal-header bredec-icon {
                cursor: pointer;
            }
            .modal-header h4 {
                font-weight: normal;
                font-size: 18px;
                margin: 0;
                color: #fff;
            }
            .modal-body p {
                font-size: 16px;
                color: #fff;
            }
            .modal-footer {
                text-align: right;
            }
            .modal-body {
                margin-bottom: 20px;
            }
          `
        ];
    }

    constructor(){
        super()
    }
    
    stateChanged(state){
        this.refreshTokenState = state.auth.refreshTokenState;
        this.isAuthenticated = state.auth.isAuthenticated;
    }
    setTokenExpiry() {
        clearTimeout(this.tokenModalInterval);
        const interval = process.env.tokenExpiryinMS;
        this.tokenModalInterval = setTimeout(() => {
          if (this.isAuthenticated) {
            // @ts-ignore
            this.open = true;
            this.timeoutStarted = true;
          }
        }, Number(interval));
    }
    handleSubmit() {
        store.dispatch(refreshToken())
        this.setTokenExpiry();
    }
    handleClose(){
        this.open = false;
        this.timeoutStarted = false;
        this.setTokenExpiry();
    }
    protected updated(_changedProperties: Map<string | number | symbol, unknown>): void {
        if(_changedProperties.has("refreshTokenState")) {
            if(!this.refreshTokenState) {
                this.open = false;
                this.timeoutStarted = false;
            }
        }
        if(_changedProperties.has("isAuthenticated")) {
            if(this.isAuthenticated && !this.timeoutStarted) {
                this.setTokenExpiry();
            } else {
                clearTimeout(this.tokenModalInterval);
                this.timeoutStarted = false;
            }
        }
    }
    protected firstUpdated(_changedProperties: Map<string | number | symbol, unknown>): void {
        const user = localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user"))
          : null;
          if(user) {
            const timeDiff =
              user && user["tokenExpiry"]
                ? moment().diff(moment(user["tokenExpiry"]), "minutes")
                : 1;
            if(this.isAuthenticated && timeDiff >= 0) {
                setTimeout(() => {
                    console.log(this.isAuthenticated)
                    if(this.isAuthenticated) {
                        // @ts-ignore
                        this.open = true;
                    }
                }, 2000);
            }
          }
    }
    render() {
        return html`
            ${this.open ? html`<div class="overlay"></div>` : ''}
            ${this.open ? html`
            <div class="modal ${this.open ? 'open' : ''}" aria-hidden="${!this.open}">
                <div class="modal-data">
                    <div class="modal-header">
                        <h4>Token expiring</h4>
                        <bredec-icon size="1.5em" @click='${this.handleClose}' prefix="fas" name="times" class="social-icons"></bredec-icon>
                    </div>
                    <div class="modal-body">
                        <p>Your admin token is expiring in few minutes, please refresh the token to continue using the admin portal or ignore</p>
                    </div>
                    <div class="modal-footer">
                        <bredec-button .disabled="${this.refreshTokenState}" .showLoading=${this.refreshTokenState} variant="base" @button-click="${this.handleSubmit}">
                            ${'Refresh Token'}
                        </bredec-button>
                    </div>
                </div>
            </div>
            
            ` : ''}
        `
    }

}

customElements.define("bredec-refresh-token-modal", RefreshToken);

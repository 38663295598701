import { UserRequest, UserResponse } from "bredec-admin-user-services-types";
import { getUserRequest, parseUserResponse } from "./gql";
import {environment} from "../../../const";

export const getCurrentUser = async (
    token: string,
    userRequest: UserRequest
): Promise<UserResponse> => {
    const { abortSignal } = userRequest;
    const requestBody = getUserRequest();
    const response = await fetch(environment.apiUrl, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          'Content-Type': 'application/json',
          'Bredec-Token': token
        },
        body: JSON.stringify(requestBody),
        signal: abortSignal
    });
    const json = await response.json();
    return parseUserResponse(json);
}

import { OfferingsSingleType } from "bredec-typings"

export const UPDATE_OFFERING = 'UPDATE_OFFERING'

export const FETCHING_PRODUCTS = 'FETCHING_PRODUCTS'
export const FETCHED_PRODUCTS = 'FETCHED_PRODUCTS'

export const FETCHING_NEWS = 'FETCHING_NEWS'
export const FETCHING_MORE_NEWS = 'FETCHING_MORE_NEWS'
export const FETCHED_NEWS = 'FETCHED_NEWS'
export const FETCHED_MORE_NEWS = 'FETCHED_MORE_NEWS'

export const FETCHING_SERVICES = 'FETCHING_SERVICES'
export const FETCHED_SERVICES = 'FETCHED_SERVICES'
export const FETCHING_MORE_SERVICES = 'FETCHING_MORE_SERVICES'
export const FETCHED_MORE_SERVICES = 'FETCHED_MORE_SERVICES'

export const FETCHING_EVENTS = 'FETCHING_EVENTS'
export const FETCHED_EVENTS = 'FETCHED_EVENTS'

export function updateOffering(offeringType: string) {
    return {
      type: UPDATE_OFFERING,
      payload: offeringType
    }
}

export function fetchingProducts() {
  return {
    type: FETCHING_PRODUCTS
  }
}
export function fetchedProducts(productsLIst: OfferingsSingleType[]) {
    return {
      type: FETCHED_PRODUCTS,
      payload: productsLIst
    }
}


export function fetchingNews() {
  return {
    type: FETCHING_NEWS
  }
}

export function fetchingMoreNews() {
  return {
    type: FETCHING_MORE_NEWS
  }
}

export function fetchedNews(newsList: any) {
    return {
      type: FETCHED_NEWS,
      payload: newsList
    }
}
export function fetchedMoreNews(newsList: any) {
    return {
      type: FETCHED_MORE_NEWS,
      payload: newsList
    }
}
export function fetchingServices() {
  return {
    type: FETCHING_SERVICES
  }
}
export function fetchingMoreServices() {
  return {
    type: FETCHING_MORE_SERVICES
  }
}
export function fetchedServices(serviceList: any) {
    return {
      type: FETCHED_SERVICES,
      payload: serviceList
    }
}
export function fetchedMoreServices(serviceList: any) {
    return {
      type: FETCHED_MORE_SERVICES,
      payload: serviceList
    }
}
export function fetchingEvents() {
  return {
    type: FETCHING_EVENTS
  }
}
export function fetchedEvents(eventList: OfferingsSingleType[]) {
    return {
      type: FETCHED_EVENTS,
      payload: eventList
    }
}
export const FETCHING_PORTFOLIO = "PORTFOLIO/FETCHING";
export const fetchPortfolios = () => {
  return {
    type: FETCHING_PORTFOLIO,
  };
};

export const FETCHED_PORTFOLIOS = "PORTFOLIO/FETCHED";
export const fetchedPortfolios = (data) => {
  return {
    type: FETCHED_PORTFOLIOS,
    payload: data,
  };
};
export const FETCHED_SINGLE_PORTFOLIOS = "SINGLE_PORTFOLIO/FETCHED";
export const fetchedSinglePortfolio = (data) => {
  return {
    type: FETCHED_SINGLE_PORTFOLIOS,
    payload: data,
  };
};
export const FETCHED_SINGLE_NEWS = "SINGLE_NEWS/FETCHED";
export const fetchedSingleNews = (data) => {
  return {
    type: FETCHED_SINGLE_NEWS,
    payload: data,
  };
};

export const FETCHING_ABOUTUS = "ABOUTUS/FETCHING";
export const fetchAboutUs = () => {
  return {
    type: FETCHING_PORTFOLIO,
  };
};

export const FETCHED_ABOUTUS = "ABOUTUS/FETCHED";
export const fetchedAboutUs = (data) => {
  return {
    type: FETCHED_ABOUTUS,
    payload: data,
  };
};
export const FETCHED_SINGLE_ABOUTUS = "SINGLE_ABOUTUS/FETCHED";
export const fetchedSingleAboutUs = (data) => {
  return {
    type: FETCHED_SINGLE_ABOUTUS,
    payload: data,
  };
};
const widgetFetchAbortSignalMap: Map<
    string,
    Map<string, AbortController>
> = new Map();

const abortSignalManager = {
    getAbortSignal(id: string, request: string): AbortSignal {
        if(widgetFetchAbortSignalMap.has(id)) {
            if(widgetFetchAbortSignalMap.get(id).has(request)) {
                let abortController = widgetFetchAbortSignalMap
                    .get(id)
                    .get(request);
                abortController.abort();

                abortController = new AbortController();
                widgetFetchAbortSignalMap.get(id).set(request, abortController);
                return abortController.signal;
            } else {
                const abortController = new AbortController();
                widgetFetchAbortSignalMap.get(id).set(request, abortController);
                return abortController.signal;
            }
        } else {
            const fetchAbortControllerMap: Map<
                string,
                AbortController
            > = new Map();
            const abortController = new AbortController();
            fetchAbortControllerMap.set(request, abortController);
            widgetFetchAbortSignalMap.set(id, fetchAbortControllerMap);

            return abortController.signal;
        }
    },
    abortAll(id: string): void {
        if(widgetFetchAbortSignalMap.has(id)) {
            const abortController = widgetFetchAbortSignalMap.get(id);
            abortController.forEach(controller => {
                controller.abort();
            })
        }
    },
    abort(id: string, request: string): void {
      if (widgetFetchAbortSignalMap.has(id)) {
        const fetchAbortControllerMap = widgetFetchAbortSignalMap.get(id);
        if (fetchAbortControllerMap.has(request)) {
          const abortController = fetchAbortControllerMap.get(request);
          abortController.abort();
        }
      }
    },
}

export default abortSignalManager;
export const FETCHING_PORTFOLIO = "ADMIN/PORTFOLIO/FETCHING";
export const fetchPortfolios = () => {
  return {
    type: FETCHING_PORTFOLIO,
  };
};

export const FETCHED_PORTFOLIOS = "ADMIN/PORTFOLIO/FETCHED";
export const fetchedPortfolios = (data) => {
  return {
    type: FETCHED_PORTFOLIOS,
    payload: data,
  };
};
export const FETCHED_SINGLE_PORTFOLIOS = "ADMIN/SINGLE_PORTFOLIO/FETCHED";
export const fetchedSinglePortfolio = (data) => {
  return {
    type: FETCHED_SINGLE_PORTFOLIOS,
    payload: data,
  };
};

export const CREATE_PORTFOLIO = "ADMIN/PORTFOLIO/CREATE";
export const createAdminPortfolio = () => {
  return {
    type: CREATE_PORTFOLIO,
  };
};

export const CREATE_PORTFOLIO_SUCCESS = "ADMIN/PORTFOLIO/CREATE_SUCCESS";
export const createAdminPortfolioSuccess = (data) => {
  return {
    type: CREATE_PORTFOLIO_SUCCESS,
    payload: data,
  };
};

export const CREATE_PORTFOLIO_RESET = "ADMIN/PORTFOLIO/CREATE_RESET";
export const createAdminPortfolioReset = () => {
  return {
    type: CREATE_PORTFOLIO_RESET,
  };
};

export const UPDATE_PORTFOLIO = "ADMIN/PORTFOLIO/UPDATE";
export const updateAdminPortfolio = () => {
  return {
    type: UPDATE_PORTFOLIO,
  };
};

export const UPDATE_PORTFOLIO_SUCCESS = "ADMIN/PORTFOLIO/UPDATE_SUCCESS";
export const updateAdminPortfolioSuccess = (data) => {
  return {
    type: UPDATE_PORTFOLIO_SUCCESS,
    payload: data,
  };
};

export const UPDATE_PORTFOLIO_RESET = "ADMIN/PORTFOLIO/UPDATE_RESET";
export const updateAdminPortfolioReset = () => {
  return {
    type: UPDATE_PORTFOLIO_RESET,
  };
};

export const UPDATE_PORTFOLIO_VALUE = "ADMIN/PORTFOLIO/UPDATE_VALUE";
export const updatePortfolioValue = (key: string, value: string) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PORTFOLIO_VALUE,
      payload: {
          key,
          value
      }
    });
  };
};

export const CLEAR_SELECTED_PORTFOLIO = "ADMIN/PORTFOLIO/CLEAR";
export const clearSelectedPortfolio = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SELECTED_PORTFOLIO
    });
  };
};

export const DELETE_PORTFOLIO = "ADMIN/PORTFOLIO/DELETE";
export const deleteAdminPortfolio = () => {
  return {
    type: DELETE_PORTFOLIO,
  };
};

export const DELETE_PORTFOLIO_SUCCESS = "ADMIN/PORTFOLIO/DELETE_SUCCESS";
export const deleteAdminPortfolioSuccess = (data) => {
  return {
    type: DELETE_PORTFOLIO_SUCCESS,
    payload: data,
  };
};
export const TOGGLE_SIDEPANEL = 'ADMIN/TOGGLE_SIDEPANEL'
export const toggleSidePanel = () => { 
    return {
        type: TOGGLE_SIDEPANEL
    }
}

export const SET_PAGE_TITLE = 'ADMIN/SET_PAGE_TITLE'
export const setAdminPageTitle = (title) => { 
    return {
        type: SET_PAGE_TITLE,
        payload: title
    }
}
import { AboutUs } from "bredec-about-us-types";
import { Language } from "bredec-language-service-types";
import { CreateNews, NewsShare, NewsUser, TimeStamp } from "bredec-news-services-types";
import { ImageInfo, Portfolio, TranslationTextField } from "bredec-portfolio-services-types";
import {
  AboutData,
  AboutPreferencesData,
  AboutPrivacyShieldData,
  AboutProfileData,
  AddressDetails,
  AdminPermissions,
  ApplicationData,
  AuthData,
  CheckoutData,
  MetaData,
  OfferingsData,
  OfferingsSingleType,
  ShopCategory,
  ShopData,
  ShopProduct,
  Toast,
  userData,
} from "bredec-typings";

export class BredecAboutProfileDataImpl implements AboutProfileData {
  email?: string;
  name?: string;
  phone?: string;
  avatar?: string;
  profession?: string;
  returningVisitor?: boolean;

  constructor() {
    this.email = "";
    this.name = "";
    this.phone = "";
    this.avatar = "/images/profile-picture.png";
    this.profession = "";
    this.returningVisitor = false;
  }
}

export class BredecAboutPreferencesDataImpl implements AboutPreferencesData {
  language: string;
  country: string;
  interest: any[];
  gender: string;
  age: string;

  constructor() {
    this.language = "";
    this.country = "";
    this.interest = [];
    this.gender = "";
    this.age = "";
  }
}

export class BredecAboutPrivacyShieldDataImpl
  implements AboutPrivacyShieldData
{
  gid: boolean;
  ga4: boolean;
  trackAnalytics: boolean;
  usePersonalData: boolean;
  linkedin: boolean;
  twitter: boolean;
  instagram: boolean;

  constructor() {
    this.gid = false;
    this.ga4 = false;
    this.trackAnalytics = true;
    this.usePersonalData = false;
    this.linkedin = false;
    this.twitter = false;
    this.instagram = false;
  }
}

export class BredecAboutDataImpl implements AboutData {
  profile?: AboutProfileData;
  preferences?: AboutPreferencesData;
  privacyShield?: AboutPrivacyShieldData;

  constructor() {
    this.profile = new BredecAboutProfileDataImpl();
    this.preferences = new BredecAboutPreferencesDataImpl();
    this.privacyShield = new BredecAboutPrivacyShieldDataImpl();
  }
}

export class BredecApplicationDataImpl implements ApplicationData {
  checkInitiated?: boolean;
  isVisited?: boolean;
  country?: string;
  loading?: boolean;
  pageLoading?: boolean;
  pageLoadingText?: string;

  constructor() {
    this.checkInitiated = false;
    this.isVisited = false;
    this.country = "";
    this.loading = false;
    this.pageLoading = false;
    this.pageLoadingText = '';
  }
}

export class BredecOfferingsDataImpl implements OfferingsData {
  products?: OfferingsSingleType[];
  events?: OfferingsSingleType[];
  services?: Portfolio[];
  news?: OfferingsSingleType[];
  loading?: boolean;
  selectedOffering?: string;
  aboutUs?: AboutUs;
  loadMoreNews?: boolean;
  loadMorePortfolios?: boolean;

  constructor() {
    this.products = [];
    this.events = [];
    this.services = [];
    this.news = [];
    this.loading = false;
    this.selectedOffering = "news";
    this.aboutUs = {};
    this.loadMoreNews = true;
    this.loadMorePortfolios = true;
  }
}
export class BredecShopDataImpl implements ShopData {
  product?: ShopProduct;
  products?: ShopProduct[];
  categories?: ShopCategory[];
  selectedProductId?: string;
  selectedCategoryId?: string;
  loading?: boolean;
  cart?: any[];

  constructor() {
    this.product = null;
    this.products = [];
    this.categories = [
      {
        id: "mens-outwear",
        name: "Men's Outwear",
      },
      {
        id: "ladies-outwear",
        name: "Ladies Outwear",
      },
      {
        id: "mens-tshirts",
        name: "Men's T-shirts",
      },
      {
        id: "ladies-tshirts",
        name: "Ladies T-shirts",
      },
    ];
    this.selectedCategoryId = 'mensOutwear';
    this.loading = false;
    this.selectedProductId = "news";
    this.cart = JSON.parse(localStorage.getItem('cart'))?.length ? JSON.parse(localStorage.getItem('cart')) : []
  }
}

export class BredecCheckoutDataImpl implements CheckoutData {
  billingDetails: AddressDetails;
  cardDetails: any;
  savedCards: any[];
  shippingDetails: AddressDetails;
  client_setup_intent: string;

  constructor() {
    this.cardDetails = null;
    this.savedCards = [];
    this.billingDetails = new AddressDetailsImpl();
    this.shippingDetails = new AddressDetailsImpl();
    this.client_setup_intent = null;
  }
}
export class BredecAdminPermissionsImpl implements AdminPermissions {
  canCreateUser: boolean;
  canReadUser: boolean;
  canUpdateUser: boolean;
  canDeleteUser: boolean;
  isOwnerOfUser: boolean;
  canCreateAboutUs: boolean;
  canReadAboutUs: boolean;
  canUpdateAboutUs: boolean;
  canDeleteAboutUs: boolean;
  isOwnerOfAboutUs: boolean;
  canCreateNews: boolean;
  canReadNews: boolean;
  canUpdateNews: boolean;
  canDeleteNews: boolean;
  isOwnerOfNews: boolean;
  canCreatePortfolio: boolean;
  canReadPortfolio: boolean;
  canUpdatePortfolio: boolean;
  canDeletePortfolio: boolean;
  isOwnerOfPortfolio: boolean;
  canCreateUserAccess: boolean;
  canReadUserAccess: boolean;
  canUpdateUserAccess: boolean;
  canDeleteUserAccess: boolean;
  isOwnerOfUserAccess: boolean;

  constructor() {
    this.canCreateUser = false;
    this.canReadUser= false;
    this.canUpdateUser= false;
    this.canDeleteUser= false;
    this.isOwnerOfUser= false;
    this.canCreateAboutUs= false;
    this.canReadAboutUs= false;
    this.canUpdateAboutUs= false;
    this.canDeleteAboutUs= false;
    this.isOwnerOfAboutUs= false;
    this.canCreateNews= false;
    this.canReadNews= false;
    this.canUpdateNews= false;
    this.canDeleteNews= false;
    this.isOwnerOfNews= false;
    this.canCreatePortfolio= false;
    this.canReadPortfolio= false;
    this.canUpdatePortfolio= false;
    this.canDeletePortfolio= false;
    this.isOwnerOfPortfolio= false;
    this.canCreateUserAccess= false;
    this.canReadUserAccess= false;
    this.canUpdateUserAccess= false;
    this.canDeleteUserAccess= false;
    this.isOwnerOfUserAccess= false;
  }
}

export class BredecAuthDataImpl implements AuthData {
  isAuthenticated: boolean;
  loading: boolean;
  user: userData;
  isAdmin: boolean;
  refreshTokenState: boolean;
  permissions: AdminPermissions;

  constructor() {
    this.isAuthenticated = false;
    this.loading = false;
    this.user = null;
    this.isAdmin = false;
    this.refreshTokenState = false;
    this.permissions = new BredecAdminPermissionsImpl();
  }
}

export class AddressDetailsImpl implements AddressDetails {
  firstName: string;
  lastName: string;
  address: string;
  country: string;
  city: string;
  zipCode: string;

  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.address = '';
    this.country = '';
    this.city = '';
    this.zipCode = '';
  }
}
export class MetaDataImpl implements MetaData {
  languages: Language[];
  toast: Toast;
  editor: object;
  selectedLanguage: string;
  scrollTarget: string;

  constructor() {
    this.languages = [];
    this.toast = {
      show: false,
      message: ''
    }
    this.editor = {};
    this.selectedLanguage = 'en_us'
    this.scrollTarget = '';
  }
}
export class NewsShareImpl implements NewsShare {
  Facebook?: string;
  Google?: string;
  Instagram?: string;
  LinkedIn?: string;
  Twitter?: string;
  Wikipedia?: string;

  constructor() {
    this.Facebook = '';
    this.Google = '';
    this.Instagram = '';
    this.LinkedIn = '';
    this.Twitter = '';
    this.Wikipedia = '';
  }
}

export class NewsUserImpl implements NewsUser {
  Alias: string;
  FirstName: string;
  Key: string;
  Mail: string;
  Name: string;
  Share: NewsShare;
  Picture: ImageInfo;

  constructor() {
    this.Alias = '';
    this.FirstName = '';
    this.Key = '';
    this.Mail = '';
    this.Name = '';
    this.Share = new NewsShareImpl()
  }
}

export class CreateNewsImpl implements CreateNews {
  ID?: string;
  Label?: string[];
  Header?: string;
  Publication?: boolean;
  HighLight?: boolean;
  HeaderBackgroundIMG?: ImageInfo;
  ShortDescription?: TranslationTextField[];
  FullDescription?: TranslationTextField[];
  Country?: string;
  PublishDate?: TimeStamp;
  ReadingTime?: string;
  Author?: NewsUser[];
  Share?: NewsShare;
  Link?: string;

  constructor() {
    this.ID = '';
    this.Label = [];
    this.Header = '';
    this.Publication = false;
    this.HighLight = false;
    this.HeaderBackgroundIMG = undefined;
    this.ShortDescription = [];
    this.FullDescription = [];
    this.Country = '';
    this.PublishDate = {
      Time: ''
    };
    this.ReadingTime = '';
    this.Author = [];
    this.Share = {};
    this.Link = '';
  }

}
import { TOGGLE_SIDEPANEL, SET_PAGE_TITLE } from '../../actions/admin/meta'


const INITIAL_STATE = {}
  
export const metaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
        case TOGGLE_SIDEPANEL: {
            return {
                ...state,
                sidepanel: !state['sidepanel']
            }
        }
        case SET_PAGE_TITLE: {
          const { payload } = action;
            return {
                ...state,
                pageTitle: payload
            }
        }
        default:
        return state
  }
}

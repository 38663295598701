export const FETCHING_USER_ACCESS = "ADMIN/USER_ACCESS/FETCHING";
export const fetchUserAccess = () => {
  return {
    type: FETCHING_USER_ACCESS,
  };
};

export const FETCHED_USER_ACCESS = "ADMIN/USER_ACCESS/FETCHED";
export const fetchedUserAccess = (data) => {
  return {
    type: FETCHED_USER_ACCESS,
    payload: data,
  };
};
export const FETCHED_USER_GROUP_ACCESS = "ADMIN/USER_GROUP_ACCESS/FETCHED";
export const fetchedUserGroupAccess = (data) => {
  return {
    type: FETCHED_USER_GROUP_ACCESS,
    payload: data,
  };
};


export const FETCHED_SINGLE_USER_ACCESS = "ADMIN/SINGLE_USER_ACCESS/FETCHED";
export const fetchedSingleUserAccess = (data) => {
  return {
    type: FETCHED_SINGLE_USER_ACCESS,
    payload: data,
  };
};

export const FETCHED_SINGLE_USER_GROUP_ACCESS = "ADMIN/SINGLE_USER_GROUP_ACCESS/FETCHED";
export const fetchedSingleUserGroupAccess = (data) => {
  return {
    type: FETCHED_SINGLE_USER_GROUP_ACCESS,
    payload: data,
  };
};

export const CREATE_USER_ACCESS = "ADMIN/USER_ACCESS/CREATE";
export const createUserAccess = () => {
  return {
    type: CREATE_USER_ACCESS,
  };
};

export const CREATE_USER_ACCESS_SUCCESS = "ADMIN/USER_ACCESS/CREATE_SUCCESS";
export const createUserAccessSuccess = (data) => {
  return {
    type: CREATE_USER_ACCESS_SUCCESS,
    payload: data,
  };
};

export const CREATE_USER_ACCESS_RESET = "ADMIN/USER_ACCESS/CREATE_RESET";
export const createUserAccessReset = () => {
  return {
    type: CREATE_USER_ACCESS_RESET,
  };
};

export const UPDATE_USER_ACCESS = "ADMIN/USER_ACCESS/UPDATE";
export const updateUserAccess = () => {
  return {
    type: UPDATE_USER_ACCESS,
  };
};

export const UPDATE_USER_ACCESS_SUCCESS = "ADMIN/USER_ACCESS/UPDATE_SUCCESS";
export const updateUserAccessSuccess = (data) => {
  return {
    type: UPDATE_USER_ACCESS_SUCCESS,
    payload: data,
  };
};

export const UPDATE_USER_ACCESS_RESET = "ADMIN/USER_ACCESS/UPDATE_RESET";
export const updateUserAccessReset = () => {
  return {
    type: UPDATE_USER_ACCESS_RESET,
  };
};

export const CLEAR_USER_ACCESS = "ADMIN/USER_ACCESS/CLEAR";
export const clearUserAccess = () => {
  return {
    type: CLEAR_USER_ACCESS,
  };
};


export const UPDATE_SELECTED_SINGLE_USER_ACCESS = "ADMIN/USER_ACCESS/SELECTED_SINGLE_USER_ACCESS";
export const updateSingleUserAccess = (data) => {
  return {
    type: UPDATE_SELECTED_SINGLE_USER_ACCESS,
    payload: data,
  };
};


export const UPDATE_SELECTED_USER_TYPE = "ADMIN/USER_ACCESS/SELECTED_USER_TYPE";
export const updateSelectedUserType = (data) => {
  return {
    type: UPDATE_SELECTED_USER_TYPE,
    payload: data,
  };
};
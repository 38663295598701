import { LitElement, html, css, property } from "lit-element";
import 'lit-toast/lit-toast.js';
import { connect } from "pwa-helpers";
import { hideToast } from "../redux/actions/meta";
import store from "../redux/store";

export class BredecToast extends connect(store)(LitElement) {
    @property()
    message: string;
    @property()
    show: boolean;

    static get styles() {
        return css`
        :host{
            --lt-background-color: var(--primary, --primary);
            --lt-color: #ffffff;
            --lt-padding: 16px;
            --lt-bottom: 40px;
            --lt-font-size: 1em;
            --lt-font-family: sans-serif;
            --lt-border-radius: 2px;
            --lt-border: none;
            --lt-z-index: 9999;
            }
    `;
    }

    stateChanged(state) {
      this.message = state.metaData.toast.message;
      this.show = state.metaData.toast.show;
    }

    _showToast() {
        //@ts-ignore
        this.shadowRoot.querySelector('lit-toast').show(this.message, 2500);
    }

    protected updated(_changedProperties: Map<string | number | symbol, unknown>): void {
        if(_changedProperties.has('show') && this.show){
            this._showToast();
            setTimeout(() => {
                store.dispatch(hideToast())
            }, 2500);
        }
    }

    render() {
        return html`
            <lit-toast></lit-toast>
        `;
    }
}

customElements.define("bredec-toast", BredecToast);
import { css } from 'lit-element'

export default css`
@media only screen and (max-width: 480px) {
    .header {
        height: 100vh;
    }
    .logo, .logo svg {
    }
    .main-container {
        margin-left: 7rem !important;
        margin-right: 7rem !important;
    }
    .top-menu-container {
        top: 0;
    }
    .intro-container {
        padding-right: 0 !important;
    }
    .intro-text-title {
        font-size: 5rem !important;
    }
    .intro-text-subtitle {
        font-size: 2.6rem !important;
    }
    .nav-container{
        padding: 0px 0px 0px 20px;
        margin: 50px 20px 0px;
    }
    .nav-container li a.primary-link {
        margin-bottom: 20px;
        width: 100%;
    }
    .menu-wrap .sub-menu {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-row-gap: 20px;
        margin-bottom: 20px !important;
    }
    .menu-wrap .sub-menu::before{
        content: none;
    }
    .profile-menu {
        margin-bottom: 10px;
    }
    .profile-menu ul {
        flex-wrap: wrap;
    }
    .profile-menu ul li {
        margin-bottom: 30px;
    }
    .flip-card {
        width: 50px !important;
        height: 50px !important;
    }
    .flip-card.hover {
        width: 70px !important;
        height: 70px !important;
    }
    .flip-card-front img {
        width: 100%;
    }
    #clients {
        width: 100% !important;
    }
    #clients .clients-wrap ul li {
        width: 100% !important;
        min-width: 330px;
    }
    .ticker-wrap .ticker__item {
        font-size: 38px;
    }
    .watermark {
        font-size: 48px;
    }
    .company {
        padding: 0;
        height: auto;
    }
    .company-info {
        padding-left: 0;
    }
    .mission {
        padding: 0;
    }
    .tab {
        padding:0;
        height: auto;
    }
    .tab button {
        padding: 10px 8px;
        font-size: 16px;
    }
    .tab .tab-btns .active::after {
        bottom: -10px;
        left: 10px;
        height: 2px;
    }
    .tablinks, .tabcontent {
        bottom: 0;
    }
    .tabcontent {
        padding-left: 0px;
        width: 100%;
    }
    .tabcontent p {
        font-size: 13px;
    }
    .domain .domainContent p {
        padding: 0px 20px;
        font-size: 12px;
    }
    fieldset {
        margin: 0 0 20px 0;
    }
    .title-caption {
        margin-bottom: 12px;
    }
    .about-title {
        font-size: 8vw;
        margin-bottom: 20px;
    }
    .about-address {
        padding-left: 30px;
    }
    .service-container > div.selected {
        width: 100%;
    }
    .blog-container .header {
        height: 80vh;
    }
    .blog-container .header .heading h2 {
        font-size: 24px;
    }
    .blog-data-container .container {
        width: 100%;
    }
    .blog-tags {
        flex-wrap: wrap;
    }
    .blog-tags .tag {
        margin-right: 0px;
        margin-bottom: 20px;
        width: 100%;
        text-align: center;
    }
    .author-details > div {
        width: 100%;
        min-width: auto;
    }
    .ratings-container {
        flex-wrap: wrap;
    }
    .ratings-container .ratings-scales {
        flex-wrap: wrap;
        margin-right: 30px;
    }
    .ratings-container .ratings-scales .scale {
        flex: 0 0 35%;
        margin-bottom: 12px;
    }
    .comment-info .reply-like {
        width: 100%;
    }
    .write-comment .input-group input {
        width: 100%;
    }
    .comments .comment-info .name {
        align-items: flex-start;
        flex-flow: column nowrap;
    }
    .comment-info .name p {
        margin-left: 0;
        margin-top: 6px;
    }
    form.search-form input[type="text"] {
        width: 80%;
    }
    form.search-form button {
        left: 80%;
    }
    .recent-blog {
        width: 95%;
    }
    .blog-title h3 {
        font-size: 24px;
    }
    .tags p {
        min-width: 40%;
    }
    .write-comment {
        justify-content: space-evenly;
    }
    .write-comment .input-group {
        display: flex;
        flex-direction: row;
    }
    .portfolio-container .header {
        height: 80vh;
        width: 100%;
        position: relative;
    }
    .portfolio-container .header .heading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
    .heading img {
        margin: 0;
    }
    .heading h2 {
        margin: 0;
    }
    .portfolio-container .header .heading h2 {
        font-size: 24px;
    }
    .portfolio-data-container .container {
        width: 100%;
    }
    .title {
        height: 50px;
    }
    .title .watermark {
        font-size: 48px;
    }
    .project-info {
        padding-top: 10px;
        padding-left: 10px;
        height: auto;
    }
    .portfolio-content-container .project-info div {
        width: 50%;
    }
    .client-testimonial .content .client-info {
        max-width: 75%;
    }
    .top-menu {
        display: none;
    }
    .shop-menu ul {
        flex-direction: column;
    }
}
@media only screen and (min-width: 768px) and (max-width: 940px) {
    .header {
        height: 50vh;
    }
    .logo, .logo svg {
    }
    .main-container {
        margin-left: 7rem !important;
        margin-right: 7rem !important;
    }
    .top-menu-container {
        top: 0;
    }
    .intro-container {
        padding-right: 0 !important;
        justify-content: center;
    }
    .intro-text-title {
        font-size: 7rem !important;
    }
    .about h1 {
        font-size: 32px;
    }
    .company-info h2 {
        font-size: 30px;
    }
    .intro-text-subtitle {
        font-size: 2.6rem !important;
    }
    .nav-container{
        padding: 0px 0px 0px 20px;
        margin: 50px 20px 0px;
    }
    .nav-container li a.primary-link {
        margin-bottom: 20px;
        width: 100%;
    }
    .menu-wrap .sub-menu {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-row-gap: 20px;
        margin-bottom: 20px !important;
    }
    .menu-wrap .sub-menu::before{
        content: none;
    }
    .profile-menu {
        margin-bottom: 10px;
    }
    .profile-menu ul {
        flex-wrap: wrap;
    }
    .profile-menu ul li {
        margin-bottom: 30px;
    }
    .flip-card {
        width: 50px !important;
        height: 50px !important;
    }
    .flip-card.hover {
        width: 100px !important;
        height: 100px !important;
    }
    .flip-card-front img {
        width: 100%;
    }
    .ticker-wrap .ticker__item {
        font-size: 38px;
    }
    .watermark {
        font-size: 48px;
    }
    .company {
        padding: 0;
        height: auto;
    }
    .company-info {
        padding-left: 0;
    }
    .mission {
        padding: 0;
    }
    .tab {
        padding:0;
        height: auto;
    }
    .tab button {
        padding: 10px 8px;
        font-size: 16px;
    }
    .tab .tab-btns .active::after {
        bottom: -10px;
        left: 10px;
        height: 2px;
    }
    .tablinks, .tabcontent {
        bottom: 0;
    }
    .tabcontent {
        padding-left: 0px;
        width: 100%;
    }
    .tabcontent p {
        font-size: 13px;
    }
    .domain .domainContent p {
        padding: 0px 20px;
        font-size: 12px;
    }
    fieldset {
        margin: 0 0 20px 0;
        width: 45%;
    }
    .title-caption {
        margin-bottom: 12px;
    }
    .about-title {
        font-size: 4vw;
        margin-bottom: 20px;
    }
    .about-address {
        padding-left: 30px;
    }
    .service-container > div.selected {
        width: 100%;
    }
    .about-container > div {
        justify-content: space-between;
    }

    .blog-container .header {
        height: 80vh;
    }
    .blog-container .header .heading h2 {
        font-size: 24px;
    }
    .blog-data-container .container {
        width: 100%;
    }
    .blog-tags {
        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: 20px;
    }
    .blog-tags .tag {
        margin-right: 0px;
        margin-bottom: 20px;
        text-align: center;
    }
    .author-details > div {
        width: 100%;
        min-width: auto;
    }
    .ratings-container {
        flex-wrap: wrap;
    }
    .ratings-container .ratings-scales {
        flex-wrap: wrap;
        margin-right: 30px;
    }
    .ratings-container .ratings-scales .scale {
        flex: 0 0 35%;
        margin-bottom: 12px;
    }
    .comment-info .reply-like {
        width: 100%;
    }
    .write-comment .input-group input {
        width: 100%;
    }
    .comments .comment-info .name {
        align-items: flex-start;
        flex-flow: column nowrap;
    }
    .comment-info .name p {
        margin-left: 0;
        margin-top: 6px;
    }
    form.search-form input[type="text"] {
        width: 80%;
    }
    form.search-form button {
        left: 80%;
    }
    .recent-blog {
        width: 95%;
    }
    .blog-title h3 {
        font-size: 24px;
    }
    .tags p {
        min-width: 40%;
    }
    .write-comment {
        justify-content: space-evenly;
    }
    .write-comment .input-group {
        display: flex;
        flex-direction: row;
    }
    
}
`
import { fetchingApp, fetchedApp, saveApp } from '../actions/app-actions'

import { openIndexedDB, getStoreIndexedDB } from '../../database/indexDB'
import { logoutSuccess, saveAdminInfo, saveAdminPermissions, saveAuthInfo } from '../actions/auth'
import abortSignalManager from '../../utils/abort-signal-manager'
import { getCurrentUser } from '../../services/admin/user/user'
import moment from 'moment'
import { getFullUserAccess } from '../../services/admin/access/fullAccess'

export const getUserDetails = () => { 
    return async dispatch => {
     
      dispatch(fetchingApp())

      const openDB = openIndexedDB();
      const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
      if(user) {
        dispatch(saveAuthInfo(user));
        const abortSignal = abortSignalManager.getAbortSignal('bredec', 'ownUser');
        const userResponse = await getCurrentUser(user.accessToken, {abortSignal});
        if(userResponse) {
          const userDetails = {
              ...user,
              tokenExpiry: moment().add(45, 'minutes'),
          }
          localStorage.setItem('user', JSON.stringify(userDetails));
          const userAccessAbortSignal = abortSignalManager.getAbortSignal('bredec', 'user-access');
          const userAccessResponse = await getFullUserAccess(userDetails.accessToken, { filter: { UserKey: user['uid'] }, abortSignal: userAccessAbortSignal });
          dispatch(saveAdminPermissions(userAccessResponse));
          dispatch(saveAdminInfo(userResponse))
        } else {
          dispatch(logoutSuccess())
        }
      }

      openDB?.open().then(async () => {
        const db = getStoreIndexedDB(openDB)
        const getData = await db.get(1)
        if (getData && getData.data) {          
          dispatch(fetchedApp(getData.data))
        } else {
          dispatch(fetchedApp({isVisited: false}))
        }
      }).catch(() => {
        console.log("Why didn't you allow my web app to use IndexedDB?!")
      })
  
      return true
    }
}

export const saveUserDetails = (isVisited, countryName) =>{
  return dispatch => {
    const openDB = openIndexedDB()
    openDB?.open().then(() => {
      const db = getStoreIndexedDB(openDB)

      db.put({ id: 1, data:  { isVisited: isVisited, country: countryName } })
      dispatch(saveApp({ isVisited: isVisited, country: countryName }))
      dispatch(getUserDetails())

    }).catch(() => {
      console.log("Why didn't you allow my web app to use IndexedDB?!")
    })

    return true
  }
}

import { getProductDetails, getShopProducts } from "../../services/shop";
import { changeCategory, changeProduct, updateCart } from "../actions/shop-actions";

export const getCategoryProducts = (category: string) => {
  return async (dispatch) => {
    const { data, errors } = await getShopProducts(category);
    if (!errors) {
      dispatch(changeCategory(category, data));
    }
    return true;
  };
};

export const getProductDetail = (category: string, productId: string) => {
  return async (dispatch) => {
    const { data, errors } = await getProductDetails(category, productId);
    if (!errors) {
      dispatch(changeProduct(data));
    }
    return true;
  };
};

export const updateCartEffect = (cart: any[]) => {
  return async (dispatch) => {
    dispatch(updateCart(cart));
    return true;
  };
};


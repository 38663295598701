import { UserResponse } from "bredec-admin-user-services-types";
import { GQLQuery } from "bredec-typings";
import { gql } from "../../../../utils/utils";

type UserRequestDTO = {};

const Query = gql`
  query {
    user {
      Key
      Alias
      Mail
      FirstName
      Name
      Street
      PLZ
      City
      Country
      Language
    }
  }
`;

export const getUserRequest = (): GQLQuery<UserRequestDTO> => {
  return {
    query: Query,
    variables: {},
  };
};

export const parseUserResponse = (json): UserResponse => {
  const { data, errors } = json;
  if (!errors) {
    const response: UserResponse = data?.user;
    return response;
  }
};

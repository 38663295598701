import { LitElement, html, css, property } from 'lit-element';
import { routeChange } from '../utils/utils';
import ResponsiveCss from '../css/responsive-css'
import { connect } from 'pwa-helpers';
import store from '../redux/store';
import { processSignOut } from '../redux/effects/auth';
import { scrollToView } from '../redux/actions/meta';
import { environment } from '../const';
export class BredecNav extends connect(store)(LitElement) {
  @property()
  auth: any;

  static get styles() {
    return [
      css `
        #mainmenu .sidebar::before {
        content: "";
        position: fixed;
        width: 44px;
        top: 0;
        left: 0;
        left: env(safe-area-inset-left);
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 101;
        transition: opacity .4s;
        background-image: linear-gradient(0deg,#14a3db -100%,#00decf 0,#14a3db 100%,#00decf 200%);
        background-image: linear-gradient(0deg,#14a3db calc(-1*var(--gradient-width, 100%) + var(--gradient-offset, 0px) + var(--gradient-pany, 0)*var(--gradient-width, 100%)),#00decf calc(0*var(--gradient-width, 100%) + var(--gradient-offset, 0px) + var(--gradient-pany, 0)*var(--gradient-width, 100%)),#14a3db calc(1*var(--gradient-width, 100%) + var(--gradient-offset, 0px) + var(--gradient-pany, 0)*var(--gradient-width, 100%)),#00decf calc(2*var(--gradient-width, 100%) + var(--gradient-offset, 0px) + var(--gradient-pany, 0)*var(--gradient-width, 100%)));
      }
    #mainmenu .sidebar:hover::before
    {
      opacity:1
    }
    .sidebar .btn-menu {
        padding: 0;
        position: fixed;
        top: 0;
        left: 0;
        left: env(safe-area-inset-left);
        width: 46px;
        height: 44px;
        z-index: 1031;
        height: 100%;
        background:transparent;
        border:0;
        cursor:pointer;
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }
    .btn-menu:focus{
      outline:none;
    }
    .btn-menu > bredec-icon{
      position: fixed;
      left: 10px;
    }
    .sidebar .btn-menu.hide{
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s linear 300ms, opacity 300ms;
    }
    .menu-container {
      position: fixed;
      width:5rem;
      height: 100vh;
      overflow: hidden;
      left:0;
      top: 0;
      transition-delay: .3s;
      z-index: 10;
    }
    #mainmenu .menu-wrap {
        position: fixed;
        z-index: 1030;
        top: 0;
        bottom: 0;
        right: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #fff;
        overflow: hidden;
        -webkit-overflow-scrolling: auto;
        visibility: hidden;
        transform: translateX(0);
        transition: transform .4s ease-in,visibility .4s ease-in;
        background: linear-gradient(45deg,#14a3db,#00decf);
    }

    #mainmenu .menu-wrap.open {
        visibility: visible;
        transform: translateX(100%);
        transition: transform .5s ease-out,visibility .5s ease-out;
        padding:0;
    }
    #mainmenu .menu-wrap ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .nav-container ul > li {
        font-size: 3rem;
        font-weight: 700;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 1.5rem;
    }
    #mainmenu .menu-wrap .nav-container ul > li {
        transform: translateX(-50%);
        transition-property: transform;
        transition-timing-function: ease-out;
        transition-delay: .4s;
        transition-duration: 0s;
    }
    #mainmenu .menu-wrap.open .nav-container ul > li {
        transform: translateX(0);
        transition-property: transform;
        transition-timing-function: ease-out;
        transition-delay: 0s;
        transition-duration: .5s;
    }
    .nav-container li a {
      color: var(--text1);
        text-decoration: none;
    }
    .nav-container li a.primary-link {
      width: 175px;
      display: block;
    }
    .nav-container li a.active,
    .nav-container li a:hover,
    .nav-container li a:focus,
    .nav-container li span.active{
      color: #333;
    }
    .nav-container {
        width: 100%;
        padding: 0px 0px 0px 50px;
        margin: 50px auto 0px 50px;
    }
    .menu-footer-links {
        width: 100%;
        padding: 0px 0px 0px 50px;
        margin: 50px auto 50px 50px;
    }
    .sub-menu{
      display:flex;
      flex: 1 1 auto;
      align-items: center;
    }
    .sub-menu::before{
        content: '';
        display: block;
        width: 7rem;
        background-color: #fff;
        height: 1px;
        margin: 0px 2rem 1.5rem;
        align-self: flex-end;
    }
    .nav-container ul > li .sub-menu li {
      display: block;
      padding: 0 1rem;
      font-size: 1.8rem;
      margin:0;
    }
    .nav-container ul > li .sub-menu li a{
      font-weight: 300;
    }
    .top-menu-links {
      display: flex;
      grid-column-gap: 10px;
      margin-bottom: 20px;
    }
    .top-menu-links a{
      color: #fff;
      text-decoration: none;
      font-size: 15px;
      text-transform: uppercase;
      border-right: 1px solid #fff;
      padding-right: 10px
    }
    .top-menu-links a:last-child{
      border-right: none;
    }
    .menu-footer-links ul{
      display:flex;
      flex: 1 1 auto;
      align-items: center;
    }
    .menu-footer-links ul li {
      display: block;
      font-size: 1.8rem;
      margin:0;
      color: #fff;
    }
    .menu-footer-links ul li a{
      font-weight: 300;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      padding-right: 10px
    }
    `,
    ResponsiveCss];
  }

  stateChanged(state) {
    this.auth = state.auth;
  }

  firstUpdated() {
    const MAX_PAN = 0.1;

    const sidebar = this.shadowRoot.querySelector('#mainmenu .sidebar');

    window.addEventListener('mousemove', (e) => {
      const pct = Math.min(1, Math.max(0, e.clientX / innerWidth));
      const pan = (pct - 0.5) * MAX_PAN * 2;
      document.body.style.setProperty('--gradient-panx', String(pan));
      if (sidebar) {
        // @ts-ignore
        sidebar.style.setProperty(
          '--gradient-pany',
          String(-e.clientY / innerHeight),
        );
      }
    });

    window.addEventListener('touchstart', (e) => {
      if (sidebar) {
        // @ts-ignore
        sidebar.style.setProperty(
          '--gradient-pany',
          String(-e.touches[0].clientY / innerHeight),
        );
      }
    });

    window.addEventListener('touchmove', (e) => {
      if (sidebar) {
        // @ts-ignore
        sidebar.style.setProperty(
          '--gradient-pany',
          String(-e.touches[0].clientY / innerHeight),
        );
      }
    });
  }

  handleSignOut() {
    store.dispatch(processSignOut())
  }
  render() {
    return html`
    <nav id="mainmenu">
      <div class="sidebar" style="--gradient-pany:-0.99723">
        <button class="btn btn-menu" id="menu-button" @click="${() => this.toggleMenu()}">
          <bredec-icon name="bars" size="2em"></bredec-icon>
        </button>
        <button class="btn btn-menu hide" id="close-button" @click="${() => this.toggleMenu()}">
          <bredec-icon name="times" size="2em"></bredec-icon>
      </button>
      </div>
      <div class="menu-wrap bg-gradient" id="menu-page">
        <div class="nav-container">
          <div class="top-menu-links">
          ${this.auth.isAuthenticated && this.auth.isAdmin
                  ? html`<a href="javascript:;" @click="${() => this.toggleMenu('/admin/users')}" title="Dashboard">Dashboard</a>`
                  : html``}

          ${this.auth.isAuthenticated 
              ? html`<a href="javascript:;" @click="${this.handleSignOut}" title="Sign Out">Sign Out</a>`
              : html`<a href="javascript:;" @click="${() => this.toggleMenu('/auth')}" title="Sign In">Sign In</a>`}
              
            
            <a href="${environment.webUrl}" title="EN-US">EN-US</a>
          </div>
          <ul>
            <li>
              <a class="primary-link" href="${environment.webUrl}" title="Home">Home</a>
              <ul class="sub-menu">
                <li>
                    <a href="${environment.webUrl}news/all" title="News">News</a>
                </li>
                <li>
                    <a href="${environment.webUrl}portfolios/all" title="Portfolio">Portfolio</a>
                </li>
              </ul>
            </li>
            <li>
                <a class="primary-link" href="${environment.webUrl}about" title="Company">Company</a>
                <ul class="sub-menu">
                    <li>
                        <a href="${environment.webUrl}about" title="Profile">About Us</a>
                    </li>
                    <li>
                        <a href="${environment.webUrl}contact" title="Contact Us">Contact Us</a>
                    </li>
                </ul>
            </li>
            <li>
                <a class="primary-link" href="${environment.webUrl}" title="Company">My Hub</a>
                <ul class="sub-menu">
                    <li>
                        <a href="${environment.webUrl}profile" title="My Data">My Data</a>
                    </li>
                </ul>
            </li>

          </ul>
        </div>
        <div class="menu-footer-links">
          <ul>
              <li>
                  <a href="${environment.webUrl}news/QaDjPbFFFBD1ei8A2Ujf" title="News">Impressum</a>
              </li>
          </ul>
        </div>
      </div>
    </nav>
    `;
  }
  toggleMenu(path?: string, target = null) {
    const menuNavList = this.shadowRoot.getElementById('menu-page');
    const menuBtn = this.shadowRoot.getElementById('menu-button');
    const closeMenuBtn = this.shadowRoot.getElementById('close-button');
    menuNavList.classList.toggle('open');
    menuBtn.classList.toggle('hide');
    closeMenuBtn.classList.toggle('hide');
    if(path) {
      routeChange(path)
      if(target) {
        store.dispatch(scrollToView(target))
      }
    }
  }

  setActive() {
    const aObj = this.shadowRoot.querySelectorAll('a');
    const pgurl = window.location.href;
    for (let i = 0; i < aObj.length; i++) {
      if (pgurl.indexOf(aObj[i].getAttribute('href')) > -1) {
        aObj[i].className = 'active';
        if (aObj[i].closest('ul').previousElementSibling) {
          aObj[i].closest('ul').previousElementSibling.className = 'active';
        }
      }else if (aObj[i].classList.contains('active')) {
        aObj[i].classList.remove('active');
        if (aObj[i].closest('ul').previousElementSibling) {
          aObj[i].closest('ul').previousElementSibling.classList.remove('active');
        }
      }
    }
  }
}

customElements.define('bredec-nav', BredecNav);

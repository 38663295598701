import { FETCHED_PRODUCTS, FETCHING_PRODUCTS } from '../../actions/admin/products'


const INITIAL_STATE = {}
  
export const productReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
        case FETCHING_PRODUCTS: {
            return {
                ...state,
                loading: true
            }
        }
        case FETCHED_PRODUCTS: {
            const { payload } = action;
            return {
                ...state,
                loading: false,
                products: payload
            }
        }
        default:
        return state
  }
}

import { LanguageRequest } from "bredec-language-service-types";
import abortSignalManager from "../../utils/abort-signal-manager";
import { getLanguages } from "../../services/languages";
import { fetchedLanguages, fetchingLanguages } from "../actions/meta";

export const fetchAllLanguages = () => {
  return async (dispatch) => {
    const languagesRequest = constructLanguagesRequest();
    dispatch(fetchingLanguages());
    const languagesResponse = await getLanguages(languagesRequest);
    dispatch(fetchedLanguages(languagesResponse));
  };
};

const constructLanguagesRequest = (): LanguageRequest => {
  const abortSignal = abortSignalManager.getAbortSignal("bredec", "languages");
  return {
    abortSignal,
  };
};

import { userData } from "bredec-typings";

export const AUTH_SUCCESS = 'AUTH/SUCCESS';
export const AUTH_FAIL = 'AUTH/FAIL';
export const AUTH_LOGOUT_SUCCESS = 'AUTH/LOGOUT_SUCCESS';
export const AUTH_SET_LOADING_STATE = 'AUTH/SET_LOADING_STATE';
export const AUTH_ADMIN_DETAILS_SUCCESS = 'AUTH/ADMIN_DETAILS_SUCCESS';
export const AUTH_TOKEN_REFRESH = 'AUTH/AUTH_TOKEN_REFRESH';
export const AUTH_TOKEN_REFRESH_SUCCESS = 'AUTH/AUTH_TOKEN_REFRESH_SUCCESS';
export const AUTH_TOKEN_REFRESH_FAIL = 'AUTH/AUTH_TOKEN_REFRESH_FAIL';
export const SET_AUTH_PERMISSIONS = 'AUTH/SET_AUTH_PERMISSIONS';

export function setLoadingState(data: boolean) {
    return {
      type: AUTH_SET_LOADING_STATE,
      payload: data
    }
}

export function saveAuthInfo(data: userData) {
    return {
      type: AUTH_SUCCESS,
      payload: data
    }
}

export function saveAdminPermissions(data) {
    return {
      type: SET_AUTH_PERMISSIONS,
      payload: data
    }
}

export function authFailed() {
  return {
    type: AUTH_FAIL,
  }
}

export function logoutSuccess() {
    return {
      type: AUTH_LOGOUT_SUCCESS,
    }
}

export function saveAdminInfo(data: any) {
    return {
      type: AUTH_ADMIN_DETAILS_SUCCESS,
      payload: data
    }
}

export function fetchToken() {
  return {
    type: AUTH_TOKEN_REFRESH
  }
}

export function fetchTokenSuccess() {
  return {
    type: AUTH_TOKEN_REFRESH_SUCCESS
  }
}

export function fetchTokenFail() {
  return {
    type: AUTH_TOKEN_REFRESH_FAIL
  }
}
import { userData } from "bredec-typings";
import abortSignalManager from "../../utils/abort-signal-manager";
import { getCurrentUser } from "../../services/admin/user/user";
import { getFullUserAccess } from "../../services/admin/access/fullAccess";
import { setLoadingState, saveAuthInfo, logoutSuccess, saveAdminInfo, authFailed, fetchToken, fetchTokenSuccess, fetchTokenFail, saveAdminPermissions } from "../actions/auth";
import { toggleToast } from "../actions/meta";
import moment from "moment";

export const processLogin = (username: string, password: string) => {
    return dispatch => {
        dispatch(setLoadingState(true))
        // @ts-ignore
        firebase.auth().signInWithEmailAndPassword(username, password)
            .then(async (userCredential) => {
                if (userCredential.user) {
                    const user: userData = {
                        accessToken: userCredential.user.za,
                        displayName: userCredential.user.displayName,
                        email: userCredential.user.email,
                        emailVerified: userCredential.user.emailVerified,
                        isAnonymous: userCredential.user.isAnonymous,
                        phoneNumber: userCredential.user.phoneNumber,
                        photoURL: userCredential.user.photoURL,
                        providerId: userCredential.additionalUserInfo.providerId,
                        refreshToken: userCredential.user.refreshToken,
                        uid: userCredential.user.uid
                    }
                    const userDetails = {
                        ...user,
                        tokenExpiry: moment().add(45, 'minutes'),
                    }
                    localStorage.setItem('user', JSON.stringify(userDetails));
                    const abortSignal = abortSignalManager.getAbortSignal('bredec', 'ownUser');
                    const userResponse = await getCurrentUser(userDetails.accessToken, { abortSignal });
                    if (userResponse) {
                        dispatch(saveAdminInfo(userResponse))
                    }
                    const userAccessAbortSignal = abortSignalManager.getAbortSignal('bredec', 'user-access');
                    const userAccessResponse = await getFullUserAccess(userDetails.accessToken, { filter: { UserKey: userDetails.uid }, abortSignal: userAccessAbortSignal });
                    dispatch(saveAdminPermissions(userAccessResponse));
                    dispatch(saveAuthInfo(userDetails));
                    dispatch(setLoadingState(false));
                }
            })
            .catch((error) => {
                dispatch(authFailed());
                dispatch(toggleToast("Incorrect username or password. Please retry"))
                console.log('Authentication failed...!!!', error);
                // ..
            });
        return true
    }
}

export const processSignUp = (data) => {
    return dispatch => {
        dispatch(setLoadingState(true))
        // @ts-ignore
        firebase.auth().createUserWithEmailAndPassword(data.username, data.password)
            .then(async (userCredential) => {
                console.log(userCredential);
                const user: userData = {
                    accessToken: userCredential.user.za,
                    displayName: userCredential.user.displayName,
                    email: userCredential.user.email,
                    emailVerified: userCredential.user.emailVerified,
                    isAnonymous: userCredential.user.isAnonymous,
                    phoneNumber: userCredential.user.phoneNumber,
                    photoURL: userCredential.user.photoURL,
                    providerId: userCredential.additionalUserInfo.providerId,
                    refreshToken: userCredential.user.refreshToken,
                    uid: userCredential.user.uid
                }
                const userDetails = {
                    ...user,
                    tokenExpiry: moment().add(45, 'minutes'),
                }
                localStorage.setItem('user', JSON.stringify(userDetails));
                const abortSignal = abortSignalManager.getAbortSignal('bredec', 'ownUser');
                const userResponse = await getCurrentUser(userDetails.accessToken, { abortSignal });
                if (userResponse) {
                    dispatch(saveAdminInfo(userResponse))
                }
                //@ts-ignore
                await firebase.firestore().collection('User').doc(user.uid).update({
                  firstName: data.firstName,
                  name: data.name,
                  mail: user.email,
                  alias: user.email,
                  isGroup: false
                });
                const userAccessAbortSignal = abortSignalManager.getAbortSignal('bredec', 'user-access');
                const userAccessResponse = await getFullUserAccess(userDetails.accessToken, { filter: { UserKey: userDetails.uid }, abortSignal: userAccessAbortSignal });
                dispatch(saveAdminPermissions(userAccessResponse));
                dispatch(saveAuthInfo(userDetails));
                dispatch(setLoadingState(false));
            })
            .catch((error) => {
                console.log('Authentication failed...!!!', error);
                dispatch(authFailed());
                dispatch(toggleToast("Sign Up Failed. Please retry"))
                // ..
            });
        return true
    }
}

export const processForgotPassword = (data) => {
    return dispatch => {
        dispatch(setLoadingState(true))
        // @ts-ignore
        firebase.auth().sendPasswordResetEmail(data)
            .then(async (result) => {
                dispatch(setLoadingState(false));
                dispatch(toggleToast("Please check your email. We sent reset email link."))
            })
            .catch((error) => {
                console.log('Authentication failed...!!!', error);
                dispatch(toggleToast("Sign Up Failed. Please retry"))
                // ..
            });
        return true
    }
}

export const processSignOut = () => {
    return dispatch => {
        localStorage.removeItem('user');
        localStorage.removeItem('fallbackUrl');
        dispatch(logoutSuccess())
    }
}

export const refreshToken = () => {
    return async dispatch => {
        dispatch(fetchToken())
        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
        const body = {
            'grant_type': 'refresh_token',
            'refresh_token': user['refreshToken']
        }
        const response = await fetch('https://securetoken.googleapis.com/v1/token?key=AIzaSyCJXRfjyKJYtmfYU8vGsKzykedmAwil1Wg', {
            method: "POST",
            credentials: "same-origin",
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });
        const json = await response.json();
        if(json) {
            user['accessToken'] = json['access_token'];
            user['tokenExpiry'] = moment().add(45, 'minutes');
            localStorage.setItem('user', JSON.stringify(user));
            dispatch(fetchTokenSuccess())
        } else {
            dispatch(fetchTokenFail())
        }
    }
}
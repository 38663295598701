export const FETCHING_ABOUTUS = "ADMIN/ABOUTUS/FETCHING";
export const fetchAboutUs = () => {
  return {
    type: FETCHING_ABOUTUS,
  };
};

export const FETCHED_ABOUTUS = "ADMIN/ABOUTUS/FETCHED";
export const fetchedAboutUs = (data) => {
  return {
    type: FETCHED_ABOUTUS,
    payload: data,
  };
};

export const FETCHED_SINGLE_ABOUTUS = "ADMIN/SINGLE_ABOUTUS/FETCHED";
export const fetchedSingleAboutUs = (data) => {
  return {
    type: FETCHED_SINGLE_ABOUTUS,
    payload: data,
  };
};


export const UPDATE_ABOUTUS = "ADMIN/ABOUTUS/UPDATE";
export const updateAdminAboutUs = () => {
  return {
    type: UPDATE_ABOUTUS,
  };
};

export const UPDATE_ABOUTUS_SUCCESS = "ADMIN/ABOUT/UPDATE_SUCCESS";
export const updateAdminAboutUsSuccess = (data) => {
  return {
    type: UPDATE_ABOUTUS_SUCCESS,
    payload: data,
  };
};

export const UPDATE_ABOUTUS_RESET = "ADMIN/ABOUTUS/UPDATE_RESET";
export const updateAdminAboutUsReset = () => {
  return {
    type: UPDATE_ABOUTUS_RESET,
  };
};

export const UPDATE_ABOUTUS_VALUE = "ADMIN/ABOUTUS/UPDATE_VALUE";
export const updateAboutUsValue = (key: string, value: string) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ABOUTUS_VALUE,
      payload: {
          key,
          value
      }
    });
  };
};

export const CLEAR_SELECTED_ABOUTUS = "ADMIN/ABOUTUS/CLEAR";
export const clearSelectedAboutUs = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SELECTED_ABOUTUS
    });
  };
};
export const FETCHING_USERS = "ADMIN/USERS/FETCHING";
export const fetchUsers = () => {
  return {
    type: FETCHING_USERS,
  };
};

export const FETCHED_USERS = "ADMIN/USERS/FETCHED";
export const fetchedUsers = (data) => {
  return {
    type: FETCHED_USERS,
    payload: data,
  };
};
export const FETCHED_SINGLE_USER = "ADMIN/SINGLE_USER/FETCHED";
export const fetchedSingleUsers = (data) => {
  return {
    type: FETCHED_SINGLE_USER,
    payload: data,
  };
};


export const UPDATE_USER = "ADMIN/USER/UPDATE";
export const updateAdminUser = () => {
  return {
    type: UPDATE_USER,
  };
};

export const UPDATE_USER_SUCCESS = "ADMIN/USER/UPDATE_SUCCESS";
export const updateAdminUserSuccess = (data) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: data,
  };
};

export const UPDATE_USER_RESET = "ADMIN/USER/UPDATE_RESET";
export const updateAdminUserReset = () => {
  return {
    type: UPDATE_USER_RESET,
  };
};

export const UPDATE_USER_VALUE = "ADMIN/USER/UPDATE_VALUE";
export const updateUserValue = (key: string, value: any) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_VALUE,
      payload: {
          key,
          value
      }
    });
  };
};

export const CLEAR_SELECTED_USER = "ADMIN/USERS/CLEAR";
export const clearSelectedUser = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SELECTED_USER
    });
  };
};

export const DELETE_USER = "ADMIN/USER/DELETE";
export const deleteAdminUser = () => {
  return {
    type: DELETE_USER,
  };
};

export const DELETE_USER_SUCCESS = "ADMIN/USER/DELETE_SUCCESS";
export const deleteAdminUserSuccess = (data) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: data,
  };
};
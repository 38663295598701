import { FETCHING_PROFILE, FETCHED_PROFILE, SAVING_PROFILE } from '../actions/about-actions'
import { BredecAboutDataImpl } from '../../models'

const INITIAL_STATE = new BredecAboutDataImpl()

export const about = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_PROFILE: {
        return {
          ...state,...action.payload,
          loading: true
        }
      }
      case FETCHED_PROFILE: {
        return {
          ...state,...action.payload,
          loading: false
        }
      }
      case SAVING_PROFILE: {
        return {
          ...state,...action.payload,
          loading: false
        }
      }
    default:
      return state
  }
}
  
import { MetaDataImpl } from '../../models'
import { FETCHED_LANGUAGES, TOGGLE_TOAST, HIDE_TOAST, UPDATE_EDITOR, SCROLL_VIEW_TARGET, CLEAR_SCROLL_VIEW_TARGET } from '../actions/meta'


const APP_STATE = new MetaDataImpl()
  
export const metaData = (state = APP_STATE, action) => {
  switch (action.type) {
    case FETCHED_LANGUAGES: {
      return {
        ...state,
        languages: action.payload
      }
    }
    case TOGGLE_TOAST: {
      return {
        ...state,
        toast: {
          show: true,
          message: action.payload
        }
      }
    }
    case HIDE_TOAST: {
      return {
        ...state,
        toast: {
          show: false,
          message: ""
        }
      }
    }
    case UPDATE_EDITOR: {
      return {
        ...state,
        editor: {
          ...state.editor,
          [action.key]: [action.value]
        }
      }
    }

    case SCROLL_VIEW_TARGET: {
      return {
        ...state,
        scrollTarget: action.payload
      }
    }
    
    case CLEAR_SCROLL_VIEW_TARGET: {
      return {
        ...state,
        scrollTarget: ''
      }
    }
    
    default:
      return state
  }
}

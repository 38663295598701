import { ShopProduct } from "bredec-typings";

export const CHANGE_CATEGORY = 'SHOP/CHANGE_CATEGORY'
export function changeCategory(category: string, products: any[]) {
    return {
        type: CHANGE_CATEGORY,
        category,
        products
    }
}

export const CHANGE_PRODUCT = 'SHOP/CHANGE_PRODUCT'
export function changeProduct(product: ShopProduct) {
    return {
        type: CHANGE_PRODUCT,
        product
    }
}

export const UPDATE_CART = 'SHOP/UPDATE_CART'
export function updateCart(cart: any[]) {
    return {
        type: UPDATE_CART,
        cart
    }
}
import { FullUserAccessRequest, FullUserAccessResponse } from "bredec-user-access-services-types";
import {environment} from "../../../const";
import { getUserAccessRequest, parseUserAccessResponse } from "./gql/fullAccess";

export const getFullUserAccess = async (
    token: string,
    userAccessRequest: FullUserAccessRequest
): Promise<FullUserAccessResponse> => {
    const { abortSignal } = userAccessRequest;
    const requestBody = getUserAccessRequest(userAccessRequest);
    const response = await fetch(environment.apiUrl, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          'Content-Type': 'application/json',
          'Bredec-Token': token
        },
        body: JSON.stringify(requestBody),
        signal: abortSignal
    });
    const json = await response.json();
    return parseUserAccessResponse(json);
}

import { combineReducers } from 'redux'
import { application }  from './app-reducer'
import {home} from './home-reducer'
import {about} from './about-reducer'
import { offerings } from './offerings-reducer'
import { shopReducer } from './shop-reducer';
import {checkoutReducer as checkout} from './checkout';
import { authReducer as auth } from './auth';
import { metaData } from './meta';

import {adminReducer as admin} from './admin/index'

const rootReducer = combineReducers({
    home,
    application,
    about,
    offerings,
    shopReducer,
    checkout,
    auth,
    metaData,
    admin
})

export default rootReducer
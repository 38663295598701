import { BredecShopDataImpl } from '../../models'
import { CHANGE_CATEGORY, CHANGE_PRODUCT, UPDATE_CART } from '../actions/shop-actions'


const INITIAL_STATE = new BredecShopDataImpl()
  
export const shopReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
        case CHANGE_CATEGORY: {
            const { category, products } = action;
            return {
                ...state,
                selectedCategoryId: category,
                products
            }
        }
        case CHANGE_PRODUCT: {
            const { product } = action;
            return {
                ...state,
                product
            }
        }
        case UPDATE_CART: {
            const { cart } = action;
            return {
                ...state,
                cart
            }
        }
        default:
        return state
  }
}
